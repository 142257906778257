import { LoginOutlined } from '@ant-design/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Submenu from './submenu';
import { Button, Flex } from 'antd';
import { Header } from 'antd/es/layout/layout';


function SHHeader(props) {
	//useContext
	const isAdmin = localStorage.getItem('isAdmin');
	//react-router/dom
	const history = useHistory();
	const Logout = () => {
		localStorage.removeItem('isAdmin');
		window.location.href = '/';
	};
	return (
		<Header style={{
			position: 'sticky',
			top: 0,
			zIndex: '9999',
			backgroundColor: '#1A1A1A',
			color: 'white',
		}}>
			<Flex align="center" justify="space-evenly" className="h-full">

				<div
					onClick={() => history.push('/')}
				>
					{isAdmin ? (
						'Admin'
					) : (
						<Flex>
							<img
								src="https://res.cloudinary.com/dfjgtdsbf/image/upload/v1706199945/logo_em2oab.png"
								alt="icon"
								className="w-[100px] cursor-pointer"
							/>
						</Flex>
					)}
				</div>
				{isAdmin ? '' : <Submenu />}
				<div>
					{isAdmin ? (
						<Button className="cursor-pointer" color="secondary" variant="outlined"
								onClick={Logout}>Đăng xuất</Button>
					) : (
						<>
							<Button
								aria-label="show 4 new mails"
								className="text-white"
								type="text"
								icon={<LoginOutlined />}
								onClick={() => history.push('/login')}
							>
								<span className="text-[16px] ml-1">Login</span>
							</Button>

						</>
					)}
				</div>
			</Flex>
		</Header>
	);
}

export default SHHeader;
