import React from 'react';
import { Col, Flex, Row } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { useHistory } from 'react-router-dom';
import { AlibabaOutlined, FacebookOutlined, InstagramOutlined, YoutubeOutlined } from '@ant-design/icons';

function SHFooter() {
	const isAdmin = localStorage.getItem('isAdmin');
	const history = useHistory();
	if (isAdmin) return '';
	return (
		<Footer className="bg-[#1A1A1A] text-white">
			<Row className="flex justify-around">
				<Col span={2}>
					<img
						src="https://res.cloudinary.com/dfjgtdsbf/image/upload/v1706199945/logo_em2oab.png"
						alt="icon"
						className="w-[100px]"
					/>
				</Col>
				<Col span={4} className="flex justify-content-center"><Flex vertical>
					<h4>OUR CONTACTS</h4>
					<p>
						(+84)969682226 & (+84)912124125
					</p>
					<p>
						Sunhaircompany@gmail.com
					</p>
				</Flex></Col>
				<Col span={3}> <Flex vertical>
					<h4>ADDRESS</h4>
					<p>
						234 Pham Van Dong street, Co Nhue ward, Bac Tu Liem district, Hanoi, Vietnam
					</p>
				</Flex></Col>
				<Col span={4} className="flex justify-content-center"> <Flex vertical>
					<h4>SOCIAL MEDIA</h4>
					<p className="text-[#c19a5b]">
						<a target="_blank" href="https://www.facebook.com/SunHairCompany"> <FacebookOutlined
							className="mr-2" />Facebook</a>
					</p>
					<p className="text-[#c19a5b]">
						<a target="_blank" href="https://www.instagram.com/sunhaircompany/">
							<InstagramOutlined className="mr-2" />
							Instagram</a>
					</p>
					<p className="text-[#c19a5b]">
						<a target="_blank" href="https://www.youtube.com/channel/UC1XZVc1o9W5eN8VJZr9xh8A">
							<YoutubeOutlined className="mr-2" />
							Youtube</a>
					</p>
					<p className="text-[#c19a5b]">
						<a target="_blank" href="https://sunhaircompany.trustpass.alibaba.com"><AlibabaOutlined
							className="mr-2" />Alibaba</a>
					</p>
				</Flex></Col>
				<Col span={3} className="flex justify-content-center"> <Flex vertical>
					<h4>WEBSITE MAP
					</h4>
					{[
						{ label: 'Home', to: '/' },
						{
							label: 'Products',
							to: '/products',
						},
						{ label: 'News', to: '/news' },
					].map(item => <p className="text-[#c19a5b] cursor-pointer" onClick={() => history.push(item.to)}>
						{item.label}
					</p>)}
				</Flex></Col>
			</Row>
		</Footer>
	);
}

export default SHFooter;
