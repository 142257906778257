import { Container, makeStyles, Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2, 0),
		marginTop: '20px',
	},

	img: {
		maxWidth: '100px',
	},
}));
function DetailsOrderdCheckout({ paymentsCheckOut = [] }) {
	const classes = useStyles();
	const params = useParams();

	const [orderDetails, setOrderDetails] = useState([]);

	useEffect(() => {
		if (params.id) {
			paymentsCheckOut.forEach((item) => {
				if (item._id === params.id) {
					setOrderDetails(item);
				}
			});
		}
	}, [params.id]);

	if (orderDetails.length === 0) return null;
	return (
		<Container className={classes.root}>
			<TableContainer component={Paper}>
				<Typography style={{ margin: '10px' }} component="h2" variant="h5">
					Thông tin khách hàng
				</Typography>
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Tên</TableCell>
							<TableCell align="center">Địa chỉ</TableCell>
							<TableCell align="center">Số điện thoại</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell component="th" scope="row">
								{orderDetails.name}
							</TableCell>
							<TableCell align="center">{orderDetails.address}</TableCell>

							<TableCell align="center">{orderDetails.phone}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Typography style={{ margin: '20px 10px' }} component="h2" variant="h5">
					Thông tin sản phẩm
				</Typography>

				<Table style={{ margin: '30px 0px' }}>
					<TableHead>
						<TableCell>Hình ảnh sản phẩm</TableCell>
						<TableCell align="center">Tên sản phẩm</TableCell>
						<TableCell align="center">Loại sản phẩm</TableCell>
						<TableCell align="center">Số lượng</TableCell>
					</TableHead>
					<TableBody>
						{orderDetails.cart.map((item) => (
							<TableRow key={item._id}>
								<TableCell>
									<img className={classes.img} src={item.images.url} alt="" />
								</TableCell>
								<TableCell align="center">{item.title}</TableCell>
								<TableCell align="center">{item.category}</TableCell>
								<TableCell align="center">{item.quantity}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	);
}

export default DetailsOrderdCheckout;
