import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import service from '../../../app/api/service';

const useStyles = makeStyles((theme) => ({
	root: {},
	img: {
		maxHeight: '720px',
		display: 'block',
		// maxWidth: '485px',
		overflow: 'hidden',
		width: '100%',
	},
	column: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	box: {
		backgroundColor: 'white',
		// padding: '50px',
	},
	title: {
		textTransform: 'capitalize',
		textAlign: 'left',
		marginLeft: '20px',
		padding: '0 20px',
		fontSize: '32px',
		width: '75%',
	},
	button: {
		marginTop: '15px',
	},
	arrowStyles: {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(50% - 15px)',
		width: 30,
		height: 30,
		cursor: 'pointer',
	},

	indicatorStyles: {
		background: '#fff',
		width: 8,
		height: 8,
		display: 'inline-block',
		margin: '0 8px',
	},
}));

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};
function Banner(props) {
	const classes = useStyles();
	const [slider, setSlider] = useState([]);
	useEffect(() => {
		try {
			service
				.callApi(
					'find',
					{
						collection: 'sliders',
						projection: {},
					},
					props.token
				)
				.then((res) => {
					setSlider(res.data.documents);
				});
		} catch (error) {
			console.log(error);
		}
	}, []);
	return (
		<div className={classes.root}>
			<Carousel
				responsive={responsive}
				containerClass="carousel-container"
				removeArrowOnDeviceType={['tablet', 'mobile']}
				itemClass="carousel-item-padding-40-px"
				infinite={true}
				showDots={true}
				autoPlay={true}
				autoPlaySpeed={3000}
			>
				{slider.map((item) => (
					<Grid className={classes.box} container key={item._id}>
						<Grid className={classes.column} item xs={12} sm={12} md={12} lg={12}>
							<img className={classes.img} src={item.images.url} alt="img" />
						</Grid>
					</Grid>
				))}
			</Carousel>
		</div>
	);
}

export default React.memo(Banner);
