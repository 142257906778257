import PropTypes from 'prop-types';
import { default as React } from 'react';
import { Image } from 'antd';

FB.propTypes = {
	product: PropTypes.object,
	handleClick: PropTypes.func,
};

function FB({ product = {} }) {
	return (
		<Image src={product.images.url} alt="Contemplative Reptile" />
	);
}

export default FB;
