import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Modal, Space, Table } from 'antd';
import service from '../../../app/api/service';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import SH_Toolbar from '../components/SH_Toolbar';
import Title from 'antd/lib/typography/Title';
import SH_none_end_detail from './SH_none_end_detail';
import dayjs from 'dayjs';


const SH_none_end = (props) => {
	const [noneEnd, setNonEnd] = useState([]);
	const [loading, setLoading] = useState(false);
	const refDetail = useRef();
	useEffect(() => {
		getNoneEnd().then(r => r);
	}, []);
	const getNoneEnd = async () => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'noneEnd',
				projection: {},
			})
			.then((res) => {
				setNonEnd(res.data.documents);
				setLoading(false);
			});
	};
	const columns = [
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{dayjs(createdAt).format('HH:mm A DD-MM-YYYY')}</span>,
		},
		{
			title: 'Ảnh',
			dataIndex: 'images',
			key: 'images',
			render: (img) => <Image
				width={50}
				src={img.url}
			/>,

		},
		{
			title: 'Sắp xếp',
			dataIndex: 'sort',
			key: 'sort',
			render: (sort) => <span>{(sort)}</span>,

		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => refDetail.current.update(record.uniqueID)} icon={<EditOutlined />}>
						Sửa
					</Button>
					<Button onClick={() => conFirm(record)} type="dashed" danger icon={<DeleteOutlined />}>
						Xoá
					</Button>
				</Space>
			),
		},
	];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa',
			content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ',
			onOk: () => {
				deleteItem(event);
			},
			footer: (_, { OkBtn, CancelBtn }) => (
				<>
					<CancelBtn />
					<OkBtn />
				</>
			),
		});
	};
	const deleteItem = (event) => {
		setLoading(true);
		service
			.callApi('deleteOne', {
				collection: 'noneEnd',
				filter: { 'uniqueID': event.uniqueID },
			})
			.then((res) => {
				setNonEnd(res.data.documents);
				setLoading(false);
				getNoneEnd().then(r => r);
			});
	};
	const showModal = () => {
		refDetail.current.create();
	};
	return (
		<>
			<Title level={2}>Ảnh</Title>
			<SH_Toolbar left={() => <Button icon={<PlusOutlined />} type="link" onClick={showModal}>
				Thêm
			</Button>} />
			<Table pagination={true} className="mt-2" loading={loading} columns={columns} dataSource={noneEnd} />
			<SH_none_end_detail ref={refDetail} getNoneEnd={getNoneEnd} />
		</>
	);
};
export default SH_none_end;