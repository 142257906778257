import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import service from '../../../app/api/service';
import { ColorPicker, Form, Input, Modal, notification } from 'antd';
import Constant from '../../../lib/constant';

function SH_color_detail(props, ref) {
	const { getColors } = props;
	const [formInstance, setFormInstance] = useState();
	const [open, setOpen] = useState(false);
	const [index, setIndex] = useState('');
	const [form] = Form.useForm();
	const refMode = useRef();
	const [initValue, setInitValue] = useState({ name: '' });
	const [api, contextHolder] = notification.useNotification();

	const openNotificationWithIcon = (type, message, description) => {
		api[type]({
			message, description,
		});
	};
	useImperativeHandle(ref, () => ({
		create: () => {
			setOpen(true);
			refMode.current = Constant.crud.create;
		},
		update: name => {
			service
				.callApi('findOne', {
					collection: 'colors',
					filter: {
						name,
					},
				})
				.then((res) => {
					setInitValue(res.data.document);
					setIndex(res.data.document);
					setOpen(true);
					refMode.current = Constant.crud.update;
				});

		},
	}));
	useEffect(() => {
		onFormInstanceReady(form);
	}, []);
	const onFormInstanceReady = (instance) => {
		setFormInstance(instance);
	};
	const handleSubmit = async (_form) => {
		try {
			if (refMode.current === Constant.crud.update) {
				service
					.callApi(
						'updateOne',
						{
							collection: 'colors',
							'filter': {
								'name': index.name,
							},
							'update': {
								'$set': {
									..._form,
								},
							},
						},
						props.token,
					)
					.then(() => {
						openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
						onCancel();
						getColors();
					});

			} else {
				console.log(_form);
				service
					.callApi(
						'insertOne',
						{
							collection: 'colors',
							document: {
								..._form,
								createdAt: new Date(),
								updatedAt: new Date(),
							},
						},
						props.token,
					)
					.then(() => {
						openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
						onCancel();
						getColors();
					});
			}

		} catch (err) {
			console.log(err);
		}
	};
	const onCancel = () => {
		setOpen(false);
		refMode.current = '';
		formInstance?.resetFields();
		setIndex('');
	};
	try {
		return (
			<>
				{contextHolder}
				<Modal
					open={open}
					title={refMode.current === Constant.crud.create ? 'Tạo mới danh mục màu' : 'Sửa danh mục màu'}
					okText={refMode.current === Constant.crud.create ? 'Tạo mới' : 'Cập nhật'}
					cancelText="Hủy"
					centered
					okButtonProps={{
						autoFocus: true,
					}}
					onCancel={onCancel}
					destroyOnClose
					onOk={async () => {
						try {
							const values = await formInstance?.validateFields();
							if (!values.hasOwnProperty('errorFields')) {
								handleSubmit(values).then(r => r);
							}
						} catch (error) {
							console.log('Failed:', error);
						}
					}}
				>

					<Form layout="vertical" form={form} name="form_in_modal" initialValues={initValue}>
						<Form.Item
							name="name"
							label="Tên"
							rules={[
								{
									required: true,
									message: 'Dữ liệu không thể để trống!',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="color"
							label="Màu"
							rules={[
								{
									required: true,
									message: 'Dữ liệu không thể để trống!',
								},
							]}
						>
							<ColorPicker value={index?.color?.metaColor} allowClear />
						</Form.Item>

					</Form>
				</Modal>
			</>

		);
	} catch (error) {
		console.log(error);
	}
}

export default forwardRef(SH_color_detail);
