import { default as React, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import SH_category from './Components/Category';
import DetailsOrderd from './Components/DetailsOrderd';
import DetailsOrderdCheckout from './Components/DetailsOrderd/detailsCheckout';
import Ordered from './Components/Odered';
import OrderedCheckout from './Components/Odered/orderedCheckout';
import SH_slider from './Components/Slider';
import SH_user from './Components/Users';
import SH_feedback from './Components/feedback';
import * as Realm from 'realm-web';
import SH_product from './Components/Products';
import SH_color from './Components/color';
import {
	DingdingOutlined,
	DockerOutlined,
	GithubOutlined,
	JavaScriptOutlined,
	LinuxOutlined,
	OpenAIOutlined,
	PieChartOutlined,
	ProductOutlined,
	RedditOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import SH_header_admin from './Components/Header';
import DashBoard from './Components/Darshboard';
import SH_news from './Components/News';
import SH_none_end from './Components/non-end';
import SH_video_introduce from './Components/video-introduce';
import { Timelapse } from '@material-ui/icons';
import SH_time_keeping from './Components/time-keeping';

const { Header, Content, Footer, Sider } = Layout;

Admin.propTypes = {};

function Admin(props) {
	const { url } = useRouteMatch();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const [collapsed] = useState(false);
	useEffect(() => {
		getToken().then(r => r);
	}, []);
	const getToken = async () => {
		const app = new Realm.App({ id: 'data-ywubf' });

		async function loginEmailPassword(email, password) {
			const credentials = Realm.Credentials.emailPassword(email, password);
			return await app.logIn(credentials);
		}

		const user = await loginEmailPassword('sunhaircompany', 'nRye6PfMkJ6XOcMO');
		localStorage.setItem('accessToken', user.accessToken);
	};
	return (
		<Layout trigger={null} collapsible collapsed={collapsed}>
			<Header style={{
				position: 'sticky',
				top: 0,
				zIndex: 1,
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
				<SH_header_admin />
			</Header>

			<Layout style={{ marginLeft: 200 }}>
				<Sider
					className="mt-4"
					style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
				>
					<div className="demo-logo-vertical" />
					<Menu theme="dark" mode="inline"
						  onClick={e => history.push(`/admin/${e.key}`)}
						  items={[
							  {
								  key: 'dashboard',
								  label: 'Dashboard',
								  icon: <PieChartOutlined />,

							  },
							  {
								  key: 'products',
								  label: 'Quản lý sản phẩm',
								  icon: <ProductOutlined />,
							  },
							  {
								  key: 'color',
								  label: 'Quản lý màu',
								  icon: <DockerOutlined />,
							  },
							  {
								  key: 'category',
								  label: 'Quản lý loại sản phẩm',
								  icon: <LinuxOutlined />,
							  },
							  {
								  key: 'slider',
								  label: 'Quản lý slide',
								  icon: <OpenAIOutlined />,
							  },
							  // {
							  //   key: 'users',
							  //   label: 'Quản lý người dùng',
							  //   icon: <DiscordOutlined />,
							  // },
							  {
								  key: 'feedback',
								  label: 'FeedBack',
								  icon: <GithubOutlined />,
							  },
							  {
								  key: 'news',
								  label: 'Quản lý tin tức',
								  icon: <DingdingOutlined />,
							  },
							  {
								  key: 'decoration',
								  label: 'Quản lý trang ảnh',
								  icon: <JavaScriptOutlined />,
							  },
							  {
								  key: 'video',
								  label: 'Video Introduce',
								  icon: <RedditOutlined />,
							  },
							  {
								  key: 'time-keeping',
								  label: 'Quản lý chấm công',
								  icon: <Timelapse />,
							  },
						  ]} />
				</Sider>
				<Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
					<Switch>
						<Route path={`${url}/products`} component={SH_product} />
						<Route path={`${url}/category`} component={SH_category} />
						<Route path={`${url}/color`} component={SH_color} />
						<Route path={`${url}/feedback`} component={SH_feedback} />
						<Route path={`${url}/news`} component={SH_news} />
						<Route path={`${url}/video`} component={SH_video_introduce} />
						<Route path={`${url}/decoration`} component={SH_none_end} />
						<Route path={`${url}/slider`} component={SH_slider} />
						<Route path={`${url}/users`} component={SH_user} />
						<Route path={`${url}/time-keeping`} component={SH_time_keeping} />
						<Route path={`${url}/ordered/:id`} component={DetailsOrderd} />
						<Route path={`${url}/orderdCheckout/:id`}>
							<DetailsOrderdCheckout paymentsCheckOut={[]} />
						</Route>
						<Route path={`${url}/orderd`} component={Ordered} />
						<Route path={`${url}/orderdCheckout`}>
							<OrderedCheckout
								paymentsCheckout={[]}
								page={page}
								handleChangePagination={(value) => setPage(value)}
							/>
						</Route>
						<Route path={`${url}`} component={DashBoard}>
							<DashBoard paymentCheckOut={[]} />
						</Route>
					</Switch>
				</Content>

			</Layout>
		</Layout>
	)
		;
}

export default Admin;
