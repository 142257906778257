import React, { useEffect, useState } from 'react';
import service from '../../../../app/api/service';

NoneEnd.propTypes = {};

function NoneEnd(props) {
	const [noneEnds, setNoneEnds] = useState([]);
	useEffect(() => {
		loadData().then(r => r);
	}, []);
	const loadData = async () => {
		service
			.callApi(
				'find',
				{
					collection: 'noneEnd',
					projection: {},
				},
				props.token,
			)
			.then((res) => {
				setNoneEnds(res.data.documents);
			});
	};
	return (
		noneEnds?.map(img => <img src={img.images.url} key={img.images.public_id} alt="img" />)
	);
}

export default NoneEnd;