import React from 'react';
import { Button, Popover, Space } from 'antd';
import { LogoutOutlined, MailOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { Typography } from '@material-ui/core';


function SH_header_admin({ handleDrawerOpen, handleLogout, open }) {
	const isAdmin = localStorage.getItem('isAdmin');
	const user = JSON.parse(localStorage.getItem('user'));
	const logout = () => {
		localStorage.clear();
		window.location.href = '/';
	};
	const content = (
		<div className="p-3">
			<Title level={3}>Hồ sơ người dùng</Title>
			<div className="flex align-items-center">
				<img className="w-[100px] h-[100px] rounded-full"
					 src="https://modernize-react.adminmart.com/assets/user-1-6d05e3ce.jpg" alt="user-avatar" />
				<div className="ml-3">
					<Title level={4}>{user.name}</Title>
					<Typography variant="body2">{user.position}</Typography>
					<Typography variant="body2"><MailOutlined className="mr-1" />{user.email}</Typography>
				</div>
			</div>
			<br />
			<Button className="h-full" icon={<LogoutOutlined />} type="primary" danger onClick={logout}>Đăng
				xuất</Button>
		</div>
	);
	return (
		<div className="flex justify-between align-items-center w-full">
			<span className="text-white font-bold">{isAdmin ? 'Admin' : 'Member'}</span>

			<Space wrap>
				<Popover content={content} trigger="click">
					<img alt="user-avatar" className="w-[30px] h-[30px] rounded-3xl cursor-pointer"
						 src="https://modernize-react.adminmart.com/assets/user-1-6d05e3ce.jpg" />
				</Popover>
			</Space>
		</div>

	);
}

export default SH_header_admin;
