import React, { useState } from 'react';
import { Button, Input, message, Upload } from 'antd';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import SH_Toolbar from '../../Admin/Components/components/SH_Toolbar';
import service from '../../app/api/service';
import toSlug from '../../utils/toSlug';

SH_user_profile.propTypes = {};

function SH_user_profile(props) {
	const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
	const [fileList, setFileList] = useState([]);

	const prop = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		}, beforeUpload: (file) => {
			setFileList([...fileList, file]);
			return false;
		}, fileList,
	};
	const save = async () => {
		if (user.new_password && user.confirm_password && user.new_password !== user.confirm_password) {
			message.error('Mật khẩu và xác nhận mật khẩu không khớp!');
			return;
		}
		let _img = { avatar: '', public_id: '' };
		if (fileList.length > 0) {
			_img = await handleUpload();
		}
		const _user = structuredClone(user);

		if (_user.new_password) {
			_user.password = _user.new_password;
		}
		if (_img) {
			_user.avatar = _img.avatar;
			_user.public_id = _img.public_id;
		}
		const { confirm_password, new_password, _id, ...remain } = _user;
		await service.callApi('updateOne', {
			collection: 'users', filter: {
				email: _user.email,
			}, update: {
				$set: {
					...remain,
				},
			},
		}, props.token);
		setUser(_user);
		localStorage.setItem('user', JSON.stringify({ ...remain, _id }));
		message.success('Thao tác thành công');
	};
	const handleUpload = async () => {
		const formData = new FormData();
		formData.append('file', fileList[0]);
		formData.append('upload_preset', 'kx4mzert');
		const res = await fetch('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/upload', {
			method: 'POST', body: formData, redirect: 'follow',
		});
		const _img = await res.json();
		setFileList([]);
		message.success('upload successfully.').then(r => r);
		return { avatar: _img.url, public_id: _img.public_id };
	};
	return (<div className="flex flex-col sticky top-0 z-10">
		<SH_Toolbar right={() => <Button icon={<SaveOutlined />} type="primary" onClick={save}>
			Lưu
		</Button>} />
		<div className="bg-white shadow-lg mt-2 rounded-2xl p-4">
			<div className="flex-none sm:flex">
				<div className=" relative h-32 w-32   sm:mb-0 mb-3">
					<img
						src={user.avatar}
						alt="aji" className=" w-32 h-32 object-cover rounded-2xl" />
					<Upload {...prop}>
						<Button
							className="absolute -right-2 bottom-2   -ml-3  text-white p-1 text-xs bg-green-400 hover:bg-green-500 font-medium tracking-wider rounded-full transition ease-in duration-300"
							icon={<UploadOutlined />}></Button>
					</Upload>
				</div>
				<div className="flex-auto sm:ml-5 justify-evenly">
					<div className="flex items-center justify-between sm:mt-2">
						<div className="flex items-center">
							<div className="flex flex-col">
								<p>Tên</p>
								<Input value={user.name}
									   onChange={e => setUser(prev => ({ ...prev, name: e.target.value }))} />
								<p>Vị trí</p>
								<Input value={user.position}
									   onChange={e => setUser(prev => ({
										   ...prev, position: e.target.value,
									   }))} />
								<br />
								<p>Mật khẩu</p>
								<Input.Password value={user.new_password}
												onChange={e => setUser(prev => ({
													...prev, new_password: e.target.value,
												}))} />
								<p>Nhập lại mật khẩu</p>

								<Input.Password value={user.confirm_password}
												onChange={e => setUser(prev => ({
													...prev, confirm_password: e.target.value,
												}))} />
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>);
}

export default SH_user_profile;