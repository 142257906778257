import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Modal, Space, Table } from 'antd';
import service from '../../../app/api/service';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import SH_Toolbar from '../components/SH_Toolbar';
import SH_slider_detail from './SH_slider_detail';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';


const SH_slider = (props) => {
	const [sliders, setSliders] = useState([]);
	const [loading, setLoading] = useState(false);
	const refDetail = useRef();
	useEffect(() => {
		getCategory().then(r => r);
	}, []);
	const getCategory = async () => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'sliders',
				projection: {},
			})
			.then((res) => {
				setSliders(res.data.documents);
				setLoading(false);
			});
	};
	const columns = [
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{dayjs(createdAt).format("HH:mm A DD-MM-YYYY")}</span>,
		},
		{
			title: 'Ảnh',
			dataIndex: 'images',
			key: 'images',
			render: (img) => <Image
				width={200}
				src={img.url}
			/>,

		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => conFirm(record)} type="dashed" danger
							icon={<DeleteOutlined />}>Xoá</Button>
				</Space>
			),
		},
	];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa',
			content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ',
			onOk: () => {
				deleteItem(event);
			},
			footer: (_, { OkBtn, CancelBtn }) => (
				<>
					<CancelBtn />
					<OkBtn />
				</>
			),
		});
	};
	const deleteItem = (event) => {
		setLoading(true);
		service
			.callApi('deleteOne', {
				collection: 'sliders',
				filter: { 'uniqueID': event.uniqueID },
			})
			.then((res) => {
				setSliders(res.data.documents);
				setLoading(false);
				getCategory().then(r => r);
			});
	};
	const showModal = () => {
		refDetail.current.create();
	};
	return (
		<>
			<Title level={2}>Slide</Title>
			<SH_Toolbar left={() => <Button icon={<PlusOutlined />} type="link" onClick={showModal}>
				Thêm
			</Button>} />
			<Table pagination={true} className="mt-2" loading={loading} columns={columns} dataSource={sliders} />
			<SH_slider_detail ref={refDetail} getCategory={getCategory} />
		</>
	);
};
export default SH_slider;