import { Container } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import * as Realm from 'realm-web';
import Banner from '../Banner';
import AboutUs from './AboutUs';
import FeedBack from './FeedBack';
import NewProduct from './NewProduct';
import VideoIntroduce from './VideoIntroduce';
import MainProduct from './MainProduct';
import service from '../../../app/api/service';
import NonEnd from './NonEnd';

HomePage.propTypes = {};

function HomePage(props) {
	const [accessToken, setAccessToken] = useState('');
	const [titles, setTitles] = useState([]);
	useLayoutEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		getToken();
	}, []);
	const getToken = async () => {
		const app = new Realm.App({ id: 'data-ywubf' });

		async function loginEmailPassword(email, password) {
			// Create an email/password credential
			const credentials = Realm.Credentials.emailPassword(email, password);
			// Authenticate the user
			const user = await app.logIn(credentials);
			// 'App.currentUser' updates to match the logged in user
			console.assert(user.id === app.currentUser.id);
			return user;
		}

		const user = await loginEmailPassword('sunhaircompany', 'nRye6PfMkJ6XOcMO');
		setAccessToken(user.accessToken);
		getSortPart(user.accessToken);
		localStorage.setItem('accessToken', user.accessToken);
	};
	const getSortPart = (token) => {
		service
			.callApi(
				'find',
				{
					collection: 'titles',
					projection: {},
				},
				token,
			)
			.then((res) => {
				setTitles(res.data.documents);
			});
	};
	const _titles = titles.filter(t => t.isShow);
	if (accessToken) {
		return (
			<>
				<Banner token={accessToken} />
				<Container>
					<AboutUs token={accessToken} />
					<MainProduct token={accessToken} title={_titles.find(x => x.component === 'category-home')} />
					<VideoIntroduce token={accessToken}
									title={_titles.find(x => x.component === 'video-introduce-home')} />
					{/*<OurService token={accessToken}*/}
					{/*			title={_titles.find(x => x.component === 'our-service-home')} />*/}
					<NewProduct token={accessToken}
								title={_titles.find(x => x.component === 'new-product-home')} />
					<FeedBack token={accessToken} title={_titles.find(x => x.component === 'feed-back-home')} />
					<NonEnd token={accessToken} />
					{/*<MainMarket />*/}
				</Container>
			</>
		);
	} else {
		return null;
	}
}

export default HomePage;
