import React from 'react';
import { Badge, Calendar, ConfigProvider } from 'antd';
import locale from 'antd/locale/vi_VN';
import dayjs from 'dayjs';

import 'dayjs/locale/vi';

dayjs.locale('vi');

const getMonthData = (value) => {
	if (value.month() === 8) {
		return 1394;
	}
};
const SH_Calendar = ({ timekeeping = [] }) => {
	const monthCellRender = (value) => {
		const num = getMonthData(value);
		return num ? (
			<div className="notes-month">
				<section>{num}</section>
				<span>Backlog number</span>
			</div>
		) : null;
	};
	const dateCellRender = (value, _timekeeping = timekeeping) => {
		const listData = timekeeping?.filter((item) => item.date.split('/')[0] == value.date() && item.date.split('/')[1] == (value.month() + 1) && item.date.split('/')[2] == value.year());
		return (
			<ul className="events">
				{listData.map((item) => (
					<li key={item.content}>
						<Badge status={item.type} text={item.content} />
					</li>
				))}
			</ul>
		);
	};
	const cellRender = (current, info) => {
		if (info.type === 'date') return dateCellRender(current, timekeeping);
		if (info.type === 'month') return monthCellRender(current);
		return info.originNode;
	};
	return <ConfigProvider locale={locale}><Calendar cellRender={cellRender} mode="month" /></ConfigProvider>;
};
export default SH_Calendar;