import { Box, Button, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import service from '../../../app/api/service';
import RecentProducts from './recentProducts';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: '50px', marginBottom: '50px',
	}, box: {
		padding: theme.spacing(3),
	}, infor: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: '15px', fontSize: '16px',
		}, display: 'flex', justifyContent: 'space-between', height: '100%', flexDirection: 'column',
	}, flex: {
		display: 'flex', alignItems: 'center',
	}, title: {
		textTransform: 'capitalize', fontWeight: '600', [theme.breakpoints.down('xs')]: {
			fontSize: '24px',
		},
	}, price: {
		fontWeight: '600', fontSize: theme.spacing(4), margin: theme.spacing(2, 0), color: '#2a2a86',
	}, description: {
		margin: theme.spacing(2, 0),
		lineHeight: '30px',
		padding: '20px',
		fontWeight: '400',
		[theme.breakpoints.down('xs')]: {
			fontSize: '24px',
		},
	}, pricepercen: {
		marginLeft: '10px', fontSize: theme.spacing(2), margin: theme.spacing(2, 0),
	}, originPrice: {
		fontSize: '12px', textDecoration: 'line-through', margin: '0 5px',
	}, wrap_img: {
		display: 'flex', [theme.breakpoints.down('xs')]: {
			margin: '10px',
		},
	}, img: {
		maxWidth: '444px', maxHeight: '444px', margin: 'auto', [theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
		},
	}, wap: {
		margin: theme.spacing(2, 0),
	}, content: {
		marginTop: '50px',
	}, wrap_price: {
		backgroundColor: '#fafafa', borderRadius: '5px', margin: '10px 0',
	}, status: {
		width: '50px',
		height: '50px',
		color: '#00b853',
		borderRadius: '5px',
		padding: '5px',
		backgroundColor: '#e5f8ed',
	}, button: {
		[theme.breakpoints.up('sm')]: {
			maxWidth: '200px',
		}, padding: '10px', borderRadius: '25px',
	}, category: {
		'&:hover': {
			textDecoration: 'underline',
		},
	},
}));

function SH_detail_product() {
	const [products] = useState([]);
	const [allProduct] = useState([]);
	const [category, setCategory] = useState([]);

	const params = useParams();
	const [product, setProduct] = useState({});
	const classes = useStyles();
	let isService = '';

	useEffect(() => {
		if (params.id) {
			try {
				getItem().then(r => r);
			} catch (error) {
				console.log(error);
			}
		}
	}, [params.id, products]);
	const getItem = async () => {
		service.callApi('findOne', {
			collection: 'products',
			filter: {
				uniqueID: Number(params.id),
			},
		}).then((res) => {
			console.log(res);
			setProduct(res.data.document);
		});
	};

	function forhtmlContent() {
		return { __html: product?.content };
	}

	function forhtmlDescription() {
		return { __html: product?.description };
	}

	const addToCart = () => {
		let _products = localStorage.getItem('products');
		if (Array.isArray(_products)) {
			_products = JSON.parse(_products);
		} else {
			_products = [];
		}
		_products.push({ ...product, quantity: 1 });
		localStorage.setItem('products', JSON.stringify(_products));
	};
	const match = useRouteMatch();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [match.url]);
	if (product?.length === 0) return null; else return (<Box style={{ backgroundColor: '#F7F8FD' }}>
		<Container className={classes.root}>
			{/*<BreadCrumb str={match.url} />*/}
			<Paper elevation={0} className={classes.box}>
				<Grid container>
					<Grid className={classes.wrap_img} item xs={12} sm={12} md={6} lg={6}>
						<img className={classes.img} src={product?.images?.url} alt="" />
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<Box className={classes.infor}>
							<Typography
								className={clsx(classes.title, 'font-inter')}
								component="h2"
								variant="h4"
							>
								{product?.title}
							</Typography>


							{/*<Typography className={classes.sold} component="p" variant="body1">*/}
							{/*	Status:*/}
							{/*	<Typography className={classes.status} component="span" variant="body1">*/}
							{/*		{product.status ? 'In stock' : 'Out of stock'}*/}
							{/*	</Typography>*/}
							{/*</Typography>*/}
							<Typography
								className={clsx(classes.flex, classes.wrap_price)}
								component="p"
								variant="body1"
							>
								<Typography
									component="span"
									variant="body2"
									className={clsx(classes.price, 'font-dosis')}
								>
									<b style={{ color: 'red' }}>Contact</b>
								</Typography>

							</Typography>
							<Typography>{isService}</Typography>
							<Button
								className={classes.button}
								variant="outlined"
								color="secondary"
								onClick={addToCart}

							>
								Add to cart
							</Button>
							<Typography className={classes.category} component="p" variant="body1">
								Category:{product?.category}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Paper>

			<Grid lg={10}>
				<Box className={classes.content}>
					<h2>INFORMATION DETAIL</h2>
					<Paper>
						<Grid container>
							<div className={classes.description} dangerouslySetInnerHTML={forhtmlContent()} />
						</Grid>
					</Paper>
				</Box>
			</Grid>

			<Grid lg={10}>
				<Box className={classes.content}>
					<h2>DESCRIPTION</h2>
					<Paper>
						<Grid container>
							<Typography className={classes.description} component="p" variant="body1">
								<div
									className={clsx('font-inter', classes.isdescription)}
									dangerouslySetInnerHTML={forhtmlDescription()}
								/>
							</Typography>
						</Grid>
					</Paper>
				</Box>
			</Grid>
			<Box>
				{/* <Services /> */}
			</Box>
			<Box>
				<h2 className={classes.wap}>SIMILAR PRODUCT</h2>
				<RecentProducts products={allProduct.products} product={product} />
			</Box>
		</Container>
	</Box>);
}

export default SH_detail_product;
