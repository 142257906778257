import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Image, Input, message, Modal, Space, Table } from 'antd';
import service from '../../../app/api/service';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import SH_Toolbar from '../components/SH_Toolbar';
import Title from 'antd/lib/typography/Title';
import SH_feedback_detail from './SH_feedback_detail';
import dayjs from 'dayjs';


const SH_feedback = (props) => {
	const [feedbacks, setFeedBack] = useState([]);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState({});
	const [isEditTitle, setIsEditTitle] = useState(false);
	const refDetail = useRef();
	useEffect(() => {
		getCategory();
		getTitle();
	}, []);
	const getCategory = () => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'feedbacks',
				projection: {},
			})
			.then((res) => {
				setFeedBack(res.data.documents);
				setLoading(false);
			});
	};
	const getTitle = () => {
		setLoading(true);
		service
			.callApi('findOne', {
				collection: 'titles',
				projection: {},
				filter: {
					component: 'feed-back-home',
				},
			})
			.then((res) => {
				const { _id, ...remain } = res.data.document;
				setTitle(remain);
				setLoading(false);
			});
	};
	console.log(title);
	const columns = [
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{dayjs(createdAt).format('HH:mm A DD-MM-YYYY')}</span>,
		},
		{
			title: 'Ảnh',
			dataIndex: 'images',
			key: 'images',
			render: (img) => <Image
				width={50}
				src={img.url}
			/>,

		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => conFirm(record)} type="dashed" danger
							icon={<DeleteOutlined />}>Xoá</Button>
				</Space>
			),
		},
	];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa',
			content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ',
			onOk: () => {
				deleteItem(event);
			},
			footer: (_, { OkBtn, CancelBtn }) => (
				<>
					<CancelBtn />
					<OkBtn />
				</>
			),
		});
	};
	const deleteItem = (event) => {
		setLoading(true);
		service
			.callApi('deleteOne', {
				collection: 'feedbacks',
				filter: { 'uniqueID': event.uniqueID },
			})
			.then((res) => {
				setFeedBack(res.data.documents);
				setLoading(false);
				getCategory();
			});
	};
	const showModal = () => {
		refDetail.current.create();
	};
	const saveTitle = () => {
		service
			.callApi('updateOne', {
				collection: 'titles',
				filter: {
					'component': 'feed-back-home',
				},
				update: {
					'$set': {
						...title,
					},
				},
			})
			.then(() => {
				message.success('Thao tác thành công');
				setIsEditTitle(false);
			});
	};
	console.log(isEditTitle);
	return (
		<>
			<Title level={2}>
				<span className={`flex align-items-center text-[${title?.color}]`}>{title?.name} <Button
					className="border-0 bg-gray-100"
					onClick={() => {
						if (isEditTitle) {
							saveTitle();
						} else setIsEditTitle(true);
					}}>{isEditTitle ?
					<SaveOutlined /> : <EditOutlined />} </Button>

				</span>
			</Title>
			{isEditTitle && <Flex justify="center" gap="middle" className="mb-2">
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Tiêu đề</label>
					<Input
						value={title?.name}
						onChange={(e) => setTitle((prev) => ({ ...prev, name: e.target.value }))}
					/>
				</Flex>
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Màu chữ</label>
					<Input

						value={title?.color}
						onChange={(e) => setTitle((prev) => ({ ...prev, color: e.target.value }))}
					/>
				</Flex>
			</Flex>}

			<SH_Toolbar left={() => <Button icon={<PlusOutlined />} type="link" onClick={showModal}>
				Thêm
			</Button>} />

			<Table pagination={true} className="mt-2" loading={loading} columns={columns} dataSource={feedbacks} />
			<SH_feedback_detail ref={refDetail} getCategory={getCategory} />
		</>
	);
};
export default SH_feedback;