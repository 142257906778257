import React, { useEffect, useState } from 'react';
import service from '../../../app/api/service';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

SH_news_page.propTypes = {};

function SH_news_page(props) {
	const [news, setNews] = useState([]);
	useEffect(() => {
		loadData().then(r => r);
	}, []);
	const loadData = async () => {
		service
			.callApi(
				'find',
				{
					collection: 'news',
					projection: {},
				},
				props.token,
			)
			.then((res) => {
				setNews(res.data.documents);
			});
	};
	return (
		<div className="max-w-6xl m-auto">
			<div className="grid grid-cols-3 max-lg:grid-cols-2 gap-x-[2vw] gap-y-[2.5vw] max-md:grid-cols-1 mt-5 p-5">
				{news &&
					news.length > 0 &&
					news.map((item, index) => (
						<div
							key={index}
							data-aos={'fade-up'}
							data-aos-duration={2000}
							className="hover:scale-105 transition duration-100"
						>
							<Link to={`/news/${item.uniqueID}`}
								  className="company-item cursor-pointer"
							>
								<div className="h-[20.3vw] w-[100%] max-lg:h-[30vw] max-md:h-[53.6vw]">
									{item?.images?.url ? (
										<img
											alt="VETAG 8"
											loading="lazy"
											width="500"
											height="500"
											decoding="async"
											data-nimg="1"
											className="object-cover w-full h-full"
											src={item?.images?.url}
											style={{ color: 'transparent' }}
										/>
									) : (
										<div
											className="w-full h-full text-white text-[1.2vw] max-md:text-[2.73vw] p-[2vw] bg-gray-600">
											No Image
										</div>
									)}
								</div>
								<div className="info p-[1vw] pt-[1.5vw] max-md:p-[4.26vw]">
									<div
										className="date text-subtileNew text-[1.25vw] font-normal max-md:text-[3.2vw] max-lg:text-[2vw]">
										<span>{dayjs(item?.createdAt).format('DD/MM/YYYY')}</span>{' '}
										<span className="uppercase">
                          </span>
									</div>
									<h3 className="title text-[#394854] mt-[0.5vw] font-extrabold text-[1.586vw] line-clamp-2 min-h-[4vw] tracking-[-1.5px] max-lg:tracking-[-0.5px] leading-[1.25] max-md:text-[4.0vw] max-lg:text-[2.5vw]">
										{item?.title}
									</h3>
									<a href="#"
									   className="uppercase text-[1.11vw] font-bold mt-[1vw] text-[#3A5469] leading-[2.57] max-md:text-[3.73vw] flex items-end justify-end max-lg:text-[2vw]"
									>
										See More +
									</a>
								</div>
							</Link>

						</div>
					))}
			</div>
		</div>
	);
}

export default SH_news_page;