import { UploadOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Image, message, Modal, notification, Upload } from 'antd';
import axios from 'axios';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import service from '../../../app/api/service';
import Constant from '../../../lib/constant';

const normFile = (e) => {
	if (Array.isArray(e)) {
		return e;
	}
	return e?.fileList;
};

function SH_feedback_detail(props, ref) {
	const { getCategory } = props;
	const [formInstance, setFormInstance] = useState();
	const [open, setOpen] = useState(false);
	const [form] = Form.useForm();
	const refMode = useRef();
	const [initValue, setInitValue] = useState({ name: '' });
	const [index, setIndex] = useState('');
	const [api, contextHolder] = notification.useNotification();
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [images, setImages] = useState('');

	const openNotificationWithIcon = (type, message, description) => {
		api[type]({
			message, description,
		});
	};
	useImperativeHandle(ref, () => ({
		create: () => {
			setOpen(true);
			refMode.current = Constant.crud.create;
		}, update: name => {
			service
				.callApi('findOne', {
					collection: 'feedbacks', filter: {
						name,
					},
				})
				.then((res) => {
					setInitValue(res.data.document);
					setImages(res.data.document.images);
					setOpen(true);
					refMode.current = Constant.crud.update;
					setIndex(name);
				});

		},
	}));
	useEffect(() => {
		onFormInstanceReady(form);
	}, []);
	const onFormInstanceReady = (instance) => {
		setFormInstance(instance);
	};
	const handleSubmit = async (_form) => {
		try {
			if (refMode.current === Constant.crud.update) {
				service
					.callApi('updateOne', {
						collection: 'feedbacks', 'filter': {
							'name': index,
						}, 'update': {
							'$set': {
								..._form,
								images,
							},
						},
					}, props.token)
					.then(() => {
						openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
						onCancel();
					});

			} else {
				service
					.callApi('insertOne', {
						collection: 'feedbacks', document: {
							..._form, images, uniqueID: Math.floor(Math.random() * 10000000000000000)
							, createdAt: new Date(), updatedAt: new Date(),
						},
					}, props.token)
					.then(() => {
						openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
						onCancel();
						getCategory();
					});
			}

		} catch (err) {
			console.log(err);
		}
	};
	const handleRemoveImage = async (img) => {
		try {
			await axios.post('/api/destroy', { public_id: img.response.public_id });
		} catch (error) {
			console.log(error);
		}
	};
	const onCancel = () => {
		setOpen(false);
		refMode.current = '';
		formInstance?.resetFields();
	};

	const handleUpload = () => {
		const formData = new FormData();
		formData.append('file', fileList[0]);
		// formData.append('api_key', '525413597814261');
		formData.append('upload_preset', 'kx4mzert');
		setUploading(true);
		// try {
		// 	service.uploadFile({ file: fileList[0] }).then((res) => {
		// 		setFileList([]);
		// 		setImages({
		// 			url: res.data.url,
		// 			public_id: res.data.public_id,
		// 		});
		// 		console.log(res);
		// 		message.success('upload successfully.');
		// 	});
		// } catch (error) {
		// 	message.error('upload failed.');
		// } finally {
		// 	setUploading(false);
		// }
		// You can use any AJAX library you like
		fetch('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/upload', {
			method: 'POST',
			body: formData,
			redirect: 'follow',
		})
			.then((res) => res.json())
			.then((data) => {
				setFileList([]);
				setImages({
					url: data.url,
					public_id: data.public_id,
				});
				message.success('upload successfully.');
			})
			.catch(() => {
				message.error('upload failed.');
			})
			.finally(() => {
				setUploading(false);
			});
	};
	const prop = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			setFileList([...fileList, file]);
			return false;
		},
		fileList,
	};
	try {
		return (<>
			{contextHolder}
			<Modal
				open={open}
				title={refMode.current === Constant.crud.create ? 'Tạo mới danh mục sản phẩm' : 'Sửa danh mục sản phẩm'}
				okText={refMode.current === Constant.crud.create ? 'Tạo mới' : 'Cập nhật'}
				cancelText="Hủy"
				centered
				okButtonProps={{
					autoFocus: true,
				}}
				onCancel={onCancel}
				destroyOnClose
				onOk={async () => {
					try {
						const values = await formInstance?.validateFields();
						if (!values.hasOwnProperty('errorFields')) {
							handleSubmit(values).then(r => r);
						}
					} catch (error) {
						console.log('Failed:', error);
					}
				}}
			>
				<Form layout="vertical" form={form} name="form_in_modal" initialValues={initValue}>
					<Form.Item name="images" label="Ảnh"
							   getValueFromEvent={normFile}
					>
						<Flex align="center">
							<Upload {...prop} >
								<Button icon={<UploadOutlined />}>Chọn ảnh</Button>
							</Upload>
							<Button
								type="primary"
								onClick={handleUpload}
								disabled={fileList.length === 0}
								loading={uploading}
								style={{
									marginLeft: 16,
								}}
							>
								{uploading ? 'Đang tải' : 'Tải lên'}
							</Button>
						</Flex>
						<Flex align="center">

							<Image
								width={200}
								src={images.url}
							/>
							{/*<Button*/}
							{/*	danger*/}
							{/*	type="dashed"*/}
							{/*	className="ml-3 flex align-items-center"*/}
							{/*	onClick={handleRemoveImage}*/}
							{/*>*/}
							{/*	<DeleteOutlined />*/}
							{/*	Xóa ảnh*/}
							{/*</Button>*/}
						</Flex>
					</Form.Item>
				</Form>
			</Modal>
		</>);
	} catch (error) {
		console.log(error);
	}
}

export default forwardRef(SH_feedback_detail);
