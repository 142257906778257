import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Upload } from 'antd';
import axios from 'axios';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import service from '../../../app/api/service';
import Constant from '../../../lib/constant';

const normFile = (e) => {
	if (Array.isArray(e)) {
		return e;
	}
	return e?.fileList;
};

function SH_users_detail(props, ref) {
	const { getUsers } = props;
	const [formInstance, setFormInstance] = useState();
	const [open, setOpen] = useState(false);
	const [form] = Form.useForm();
	const refMode = useRef();
	const [initValue, setInitValue] = useState({ name: '' });
	useImperativeHandle(ref, () => ({
		create: () => {
			setOpen(true);
			refMode.current = Constant.crud.create;
		},
		update: name => {
			service
				.callApi('findOne', {
					collection: 'users',
					filter: {
						name,
					},
				})
				.then((res) => {
					setInitValue(res.data.document);
					setOpen(true);
					refMode.current = Constant.crud.update;
				});

		},
	}));
	useEffect(() => {
		onFormInstanceReady(form);
	}, []);
	const onFormInstanceReady = (instance) => {
		setFormInstance(instance);
	};
	const handleSubmit = async (_form) => {
		try {
			if (refMode.current === Constant.crud.update) {
				service
					.callApi(
						'insertOne',
						{
							collection: 'users',
							'filter': {
								'_id': _form._id,
							},
							'update': {
								'$set': {
									..._form,
								},
							},
						},
						props.token,
					)
					.then(() => {
						alert('Thành công');
						onCancel();
					});

			} else {
				service
					.callApi(
						'insertOne',
						{
							collection: 'users',
							document: {
								..._form,
								images: _form.images[0].response,
								createdAt: new Date(),
								updatedAt: new Date(),
							},
						},
						props.token,
					)
					.then(() => {
						alert('Thành công');
						onCancel();
						getUsers();
					});
			}

		} catch (err) {
			console.log(err);
		}
	};
	const handleRemoveImage = async (img) => {
		try {
			await axios.post('/api/destroy', { public_id: img.response.public_id });
		} catch (error) {
			console.log(error);
		}
	};
	const onCancel = () => {
		setOpen(false);
		refMode.current = '';
		formInstance?.resetFields();
	};
	try {
		return (
			<Modal
				open={open}
				title={refMode.current === Constant.crud.create ? 'Tạo mới sản phẩm' : 'Sửa sản phẩm'}
				okText={refMode.current === Constant.crud.create ? 'Tạo mới' : 'Cập nhật'}
				cancelText="Hủy"
				centered
				okButtonProps={{
					autoFocus: true,
				}}
				onCancel={onCancel}
				destroyOnClose
				onOk={async () => {
					try {
						const values = await formInstance?.validateFields();
						if (!values.hasOwnProperty('errorFields')) {
							handleSubmit(values).then(r => r);
						}
					} catch (error) {
						console.log('Failed:', error);
					}
				}}
			>
				<Form layout="vertical" form={form} name="form_in_modal" initialValues={initValue}>
					<Form.Item
						name="title"
						label="Tên"
						rules={[
							{
								required: true,
								message: 'Dữ liệu không thể để trống!',
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="description"
						label="Mô tả"
						rules={[
							{
								required: true,
								message: 'Dữ liệu không thể để trống!',
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="content"
						label="Nội dung"
						rules={[
							{
								required: true,
								message: 'Dữ liệu không thể để trống!',
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item name="images" label="Ảnh"
							   getValueFromEvent={normFile}
							   rules={[
								   {
									   required: true,
									   message: 'Dữ liệu không thể để trống!',
								   },
							   ]}>
						<Upload onRemove={handleRemoveImage} multiple={false} action="/api/upload"
								listType="picture-card">
							<button style={{ border: 0, background: 'none' }} type="button">
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Upload</div>
							</button>
						</Upload>
					</Form.Item>
				</Form>
			</Modal>
		);
	} catch (error) {
		console.log(error);
	}
}

export default forwardRef(SH_users_detail);
