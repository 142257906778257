import { Typography } from '@material-ui/core';
import React from 'react';
import { WhatsAppOutlined } from '@ant-design/icons';

WhatAppButton.propTypes = {};

function WhatAppButton(props) {
	return (<div className="whatsApp">
		<a
			target="_blank"
			href="https://api.whatsapp.com/send/?phone=84912251978&text&type=phone_number&app_absent=0"
		>
			<div className="content-center" style={{
				position: 'fixed',
				bottom: '50px',
				left: '30px',
			}}>
				<div className="pulse"><i className="fas fa-phone fa-2x">
					<WhatsAppOutlined />
				</i></div>
			</div>
		</a>
	</div>);
}

export default WhatAppButton;
