import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Modal, Space, Table } from 'antd';
import service from '../../../app/api/service';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import SH_Toolbar from '../components/SH_Toolbar';
import SH_news_detail from './SH_news_detail';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';

const SH_news = (props) => {
	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(false);
	const refDetail = useRef();
	useEffect(() => {
		getNews();

	}, []);
	const getNews = () => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'news',
				projection: {},
			})
			.then((res) => {
				setNews(res.data.documents);
				setLoading(false);
			});
	};
	const columns = [
		{
			title: 'Tên',
			dataIndex: 'title',
			key: 'title',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{dayjs(createdAt).format("HH:mm A DD-MM-YYYY")}</span>,
		},
		{
			title: 'Ảnh',
			dataIndex: 'images',
			key: 'images',
			render: (img) => <Image width={70} src={img.url} />,
		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => refDetail.current.update(record.uniqueID)} icon={<EditOutlined />}>
						Sửa
					</Button>
					<Button onClick={() => conFirm(record)} type="dashed" danger icon={<DeleteOutlined />}>
						Xoá
					</Button>
				</Space>
			),
		},
	];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa',
			content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ',
			onOk: () => {
				deleteItem(event);
			},
			footer: (_, { OkBtn, CancelBtn }) => (
				<>
					<CancelBtn />
					<OkBtn />
				</>
			),
		});
	};
	const deleteItem = (event) => {
		setLoading(true);
		service
			.callApi('deleteOne', {
				collection: 'news',
				filter: { uniqueID: event.uniqueID },
			})
			.then((res) => {
				setLoading(false);
				getNews();
			});
	};
	const showModal = () => {
		refDetail.current.create();
	};
	return (
		<>
			<Title level={2}>Tin tức</Title>
			<SH_Toolbar
				left={() => (
					<Button icon={<PlusOutlined />} type="link" onClick={showModal}>
						Thêm
					</Button>
				)}
			/>
			<Table pagination={true} className="mt-2" loading={loading} columns={columns} dataSource={news} />
			<SH_news_detail
				ref={refDetail}
				getNews={getNews}
			/>
		</>
	);
};
export default SH_news;
