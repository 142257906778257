import React from 'react';
import WhatAppButton from './buttons';

export default function ButtonsSocial(props) {
	const handleClick = (event) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<React.Fragment>
			<WhatAppButton />
		</React.Fragment>
	);
}
