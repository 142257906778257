import { Button, Container, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import axios from 'axios';

RegisterEmail.propTypes = {};

function RegisterEmail(props) {
	const [data, setData] = useState({});
	const handleClick = () => {
		axios.post('/api/paymentsCheckout', { ...data, cart: JSON.parse(localStorage.getItem('products')) });
	};
	return (
		<Container>
			<Grid container style={{ margin: '30px 0', textAlign: 'center' }}>
				<Grid item xs={12} sm={12} lg={12}>
					<h3 className="subHead font-family-great-viber">Contact Us</h3>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12} lg={12}>
				<div className="flex flex-col justify-content-center align-items-center">
					<TextField
						onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
						style={{ width: '80%', marginTop: '10px' }}
						variant="outlined"
						label="Your Name"
					/>
					<TextField
						onChange={(e) => setData((prev) => ({ ...prev, receiEmail: e.target.value }))}
						style={{ width: '80%', marginTop: '10px' }}
						variant="outlined"
						label="Your Email"
					/>
					<TextField
						onChange={(e) => setData((prev) => ({ ...prev, whatsapp: e.target.value }))}
						style={{ width: '80%', marginTop: '10px' }}
						variant="outlined"
						label="Your whatsapp"
					/>
					<TextField
						onChange={(e) => setData((prev) => ({ ...prev, address: e.target.value }))}
						style={{ width: '80%', marginTop: '10px' }}
						variant="outlined"
						label="Your Country"
					/>
					<TextField
						onChange={(e) => setData((prev) => ({ ...prev, message: e.target.value }))}
						style={{ width: '80%', marginTop: '10px' }}
						variant="outlined"
						label="Message"
					/>
					<Button
						style={{ margin: '10px 0' }}
						onClick={handleClick}
						variant="outlined"
						color="primary"
						type="submit"
					>
						Send Message
					</Button>
				</div>
			</Grid>
		</Container>
	);
}

export default RegisterEmail;
