import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Image, Input, message, Modal, Space, Table } from 'antd';
import service from '../../../app/api/service';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import SH_Toolbar from '../components/SH_Toolbar';
import SH_product_detail from './SH_product_detail';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';

const SH_product = (props) => {
	const [dictionary, setDictionary] = useState([]);
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState({});
	const [isEditTitle, setIsEditTitle] = useState(false);
	const refDetail = useRef();
	useEffect(() => {
		getProducts();
		getCategory().then((r) => r);
		getTitle();
	}, []);
	const getCategory = async () => {
		setLoading(true);
		const [categories, colors] = await Promise.all([
			service.callApi('find', {
				collection: 'categories',
				projection: {},
			}),
			service.callApi('find', {
				collection: 'colors',
				projection: {},
			}),
		]);
		setDictionary({
			categories: categories.data.documents,
			colors: colors.data.documents,
		});
		setLoading(false);
	};
	const getTitle = () => {
		setLoading(true);
		service
			.callApi('findOne', {
				collection: 'titles',
				projection: {},
				filter: {
					component: 'new-product-home',
				},
			})
			.then((res) => {
				const { _id, ...remain } = res.data.document;
				setTitle(remain);
				setLoading(false);
			});
	};
	const getProducts = () => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'products',
				projection: {},
			})
			.then((res) => {
				setProducts(res.data.documents);
				setLoading(false);
			});
	};
	const columns = [
		{
			title: 'Tên',
			dataIndex: 'title',
			key: 'title',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Loại sản phẩm',
			dataIndex: 'category',
			key: 'category',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Màu',
			dataIndex: 'color',
			key: 'color',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{dayjs(createdAt).format('HH:mm A DD-MM-YYYY')}</span>,
		},
		{
			title: 'Ảnh',
			dataIndex: 'images',
			key: 'images',
			render: (img) => <Image width={70} src={img.url} />,
		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => refDetail.current.update(record.uniqueID)} icon={<EditOutlined />}>
						Sửa
					</Button>
					<Button onClick={() => conFirm(record)} type="dashed" danger icon={<DeleteOutlined />}>
						Xoá
					</Button>
				</Space>
			),
		},
	];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa',
			content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ',
			onOk: () => {
				deleteItem(event);
			},
			footer: (_, { OkBtn, CancelBtn }) => (
				<>
					<CancelBtn />
					<OkBtn />
				</>
			),
		});
	};
	const deleteItem = (event) => {
		setLoading(true);
		service
			.callApi('deleteOne', {
				collection: 'products',
				filter: { uniqueID: event.uniqueID },
			})
			.then((res) => {
				setLoading(false);
				getProducts();
			});
	};
	const showModal = () => {
		refDetail.current.create();
	};
	const saveTitle = () => {
		service
			.callApi('updateOne', {
				collection: 'titles',
				filter: {
					'component': 'new-product-home',
				},
				update: {
					'$set': {
						...title,
					},
				},
			})
			.then(() => {
				message.success('Thao tác thành công');
				setIsEditTitle(false);
			});
	};
	return (
		<>
			<Title level={2}>
				<span className={`flex align-items-center text-[${title?.color}]`}>{title?.name} <Button
					className="border-0 bg-gray-100"
					onClick={() => {
						if (isEditTitle) {
							saveTitle();
						} else setIsEditTitle(true);
					}}>{isEditTitle ?
					<SaveOutlined /> : <EditOutlined />} </Button>

				</span>
			</Title>
			{isEditTitle && <Flex justify="center" gap="middle" className="mb-2">
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Tiêu đề</label>
					<Input
						value={title?.name}
						onChange={(e) => setTitle((prev) => ({ ...prev, name: e.target.value }))}
					/>
				</Flex>
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Màu chữ</label>
					<Input

						value={title?.color}
						onChange={(e) => setTitle((prev) => ({ ...prev, color: e.target.value }))}
					/>
				</Flex>
			</Flex>}
			<SH_Toolbar
				left={() => (
					<Button icon={<PlusOutlined />} type="link" onClick={showModal}>
						Thêm
					</Button>
				)}
			/>
			<Table pagination={true} className="mt-2" loading={loading} columns={columns} dataSource={products} />
			<SH_product_detail
				ref={refDetail}
				getProducts={getProducts}
				categories={dictionary?.categories}
				colors={dictionary.colors}
			/>
		</>
	);
};
export default SH_product;
