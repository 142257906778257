import React, { useEffect, useRef, useState } from 'react';
import { Button, ColorPicker, Modal, Space, Table } from 'antd';
import service from '../../../app/api/service';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import SH_Toolbar from '../components/SH_Toolbar';
import SH_color_detail from './SH_color_detail';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';


const SH_color = (props) => {
	const [colors, setColors] = useState([]);
	const [loading, setLoading] = useState(false);
	const refDetail = useRef();
	useEffect(() => {
		getColors().then(r => r);
	}, []);
	const getColors = async () => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'colors',
				projection: {},
			})
			.then((res) => {
				setColors(res.data.documents);
				setLoading(false);
			});
	};
	const columns = [
		{
			title: 'Tên',
			dataIndex: 'name',
			key: 'name',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{dayjs(createdAt).format("HH:mm A DD-MM-YYYY")}</span>,
		},
		{
			title: 'Màu',
			dataIndex: 'color',
			key: 'color',
			render: (color) => <ColorPicker value={color.metaColor} disabled/>,

		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => refDetail.current.update(record.name)} icon={<EditOutlined />}>Sửa</Button>
					<Button onClick={() => conFirm(record)} type="dashed" danger
							icon={<DeleteOutlined />}>Xoá</Button>
				</Space>
			),
		},
	];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa',
			content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ',
			onOk: () => {
				deleteItem(event);
			},
			footer: (_, { OkBtn, CancelBtn }) => (
				<>
					<CancelBtn />
					<OkBtn />
				</>
			),
		});
	};
	const deleteItem = (event) => {
		setLoading(true);
		service
			.callApi('deleteOne', {
				collection: 'colors',
				filter: { 'name': event.name },
			})
			.then((res) => {
				setLoading(false);
				getColors().then(r => r);
			});
	};
	const showModal = () => {
		refDetail.current.create();
	};
	return (
		<>
			<Title level={2}>Danh mục màu</Title>
			<SH_Toolbar left={() => <Button icon={<PlusOutlined />} type="link" onClick={showModal}>
				Thêm
			</Button>} />
			<Table pagination={true} className="mt-2" loading={loading} columns={columns} dataSource={colors} />
			<SH_color_detail ref={refDetail} getColors={getColors} />
		</>
	);
};
export default SH_color;