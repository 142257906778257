import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Image, Input, message, Modal, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';
import SH_Toolbar from '../../../Admin/Components/components/SH_Toolbar';
import SH_time_keeping_detail from './SH_time_keeping_detail';
import service from '../../../app/api/service';

const SH_time_keeping = (props) => {
	const [dictionary, setDictionary] = useState([]);
	const [timeKeeping, setTimeKeeping] = useState([]);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState({});
	const [isEditTitle, setIsEditTitle] = useState(false);
	const refDetail = useRef();
	useEffect(() => {
		getTimeKeeping();
	}, []);
	const getTimeKeeping = () => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'timeKeeping',
				projection: {},
				filter: {
					userId: JSON.parse(localStorage.getItem('user'))._id,
				},
			})
			.then((res) => {
				setTimeKeeping(res.data.documents?.map((x, r) => ({ ...x, STT: r + 1 })));
				setLoading(false);
			});
	};
	const columns = [
		{
			title: 'STT',
			dataIndex: 'STT',
			key: 'STT',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Ngày',
			dataIndex: 'date',
			key: 'date',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Giờ check in',
			dataIndex: 'checkin',
			key: 'checkin',
			render: (checkin) => <span className={checkin !== '08:30' && 'text-red-600 font-bold'}>{checkin}</span>,
		},
		{
			title: 'Giờ check out',
			dataIndex: 'checkout',
			key: 'checkout',
			render: (checkout) => <span className={checkout !== '17:30' && 'text-red-600 font-bold'}>{checkout}</span>,
		},
		{
			title: 'Số giờ làm việc thực tế',
			dataIndex: 'workingHour',
			key: 'workingHour',
			render: (workingHour) => <span
				className={Number(workingHour) !== 7.5 && 'text-red-600 font-bold'}>{workingHour > 0 ? workingHour : '--:--'}</span>,
		},
		{
			title: 'Ghi chú của quản lý',
			dataIndex: 'admin_note',
			key: 'admin_note',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{dayjs(createdAt).format('HH:mm A DD-MM-YYYY')}</span>,
		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button
						disabled={record.date.split('/')[0] !== dayjs(new Date()).format('DD/MM/YYYY').split('/')[0] || record.date.split('/')[1] !== dayjs(new Date()).format('DD/MM/YYYY').split('/')[1] || record.date.split('/')[2] !== dayjs(new Date()).format('DD/MM/YYYY').split('/')[2]}
						onClick={() => refDetail.current.update(record.uniqueID)} icon={<EditOutlined />}>
						Sửa
					</Button>
				</Space>
			),
		},
	];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa',
			content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ',
			onOk: () => {
				deleteItem(event);
			},
			footer: (_, { OkBtn, CancelBtn }) => (
				<>
					<CancelBtn />
					<OkBtn />
				</>
			),
		});
	};
	const deleteItem = (event) => {
		setLoading(true);
		service
			.callApi('deleteOne', {
				collection: 'timeKeeping',
				filter: { uniqueID: event.uniqueID },
			})
			.then((res) => {
				setLoading(false);
				getTimeKeeping();
			});
	};
	const showModal = () => {
		refDetail.current.create();
	};
	return (
		<>
			<Title level={2}>
				<span className={`flex align-items-center`}> Chấm công

				</span>
			</Title>
			{isEditTitle && <Flex justify="center" gap="middle" className="mb-2">
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Tiêu đề</label>
					<Input
						value={title?.name}
						onChange={(e) => setTitle((prev) => ({ ...prev, name: e.target.value }))}
					/>
				</Flex>
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Màu chữ</label>
					<Input

						value={title?.color}
						onChange={(e) => setTitle((prev) => ({ ...prev, color: e.target.value }))}
					/>
				</Flex>
			</Flex>}
			<SH_Toolbar
				left={() => (
					<Button icon={<PlusOutlined />} type="link" onClick={showModal}>
						Thêm
					</Button>
				)}
			/>
			<Table className="mt-2" loading={loading} columns={columns} dataSource={timeKeeping} scroll={{ y: 500 }} bordered />
			<SH_time_keeping_detail
				ref={refDetail}
				getTimeKeeping={getTimeKeeping}
				timeKeepingList={timeKeeping}
			/>
		</>
	);
};
export default SH_time_keeping;
