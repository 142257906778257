import { Box, Grid } from '@material-ui/core';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';

VideoIntroduce.propTypes = {};


function VideoIntroduce(props) {
	const { title = {} } = props;
	return (
		<Box className="overflow-hidden">
			<h3 className={`subHead font-family-great-viber text-[${title?.color}] text-center`}>{title?.name}</h3>
			<Grid container>
				<div className="w-[1000px]" dangerouslySetInnerHTML={{ __html: title?.embed }}>
				</div>

			</Grid>
		</Box>
	);
}

export default VideoIntroduce;
