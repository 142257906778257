import { UploadOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Flex, Form, Image, Input, message, notification, Upload } from 'antd';
import axios from 'axios';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import service from '../../../app/api/service';
import Constant from '../../../lib/constant';
import SH_Model from '../components/SH_Model';
import toSlug from '../../../utils/toSlug';

const defaultData = {
	title: '',
	description: '',
	content: '',
	category: '',
	images: '',
};

function SH_news_detail(props, ref) {
	const { getNews } = props;
	const [formInstance, setFormInstance] = useState();
	const [open, setOpen] = useState(false);
	const [index, setIndex] = useState(defaultData);
	const [form] = Form.useForm();
	const refMode = useRef();
	const [initValue, setInitValue] = useState(defaultData);
	const [api, contextHolder] = notification.useNotification();
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [images, setImages] = useState('');
	const [loading, setLoading] = useState(false);
	const openNotificationWithIcon = (type, message, description) => {
		api[type]({
			message,
			description,
		});
	};
	useImperativeHandle(ref, () => ({
		create: () => {
			setOpen(true);
			refMode.current = Constant.crud.create;
		},
		update: (uniqueID) => {
			service
				.callApi('findOne', {
					collection: 'news',
					filter: {
						uniqueID,
					},
				})
				.then((res) => {
					const { _id, ...remain } = res.data.document;
					setInitValue(remain);
					setImages(res.data.document.images);
					setIndex(res.data.document);
					setOpen(true);
					refMode.current = Constant.crud.update;
				});
		},
	}));
	useEffect(() => {
		onFormInstanceReady(form);
	}, []);
	const onFormInstanceReady = (instance) => {
		setFormInstance(instance);
	};
	const handleSubmit = async () => {
		try {
			if (refMode.current === Constant.crud.update) {
				setLoading(true);
				await service.callApi(
					'updateOne',
					{
						collection: 'news',
						filter: {
							uniqueID: initValue.uniqueID,
						},
						update: {
							$set: {
								...initValue,
								content: index.content,
								description: index.description,
								images,
								slug: toSlug(initValue.title) + '-' + Math.floor(Math.random() * 10000000000000),
							},
						},
					},
					props.token
				);
				openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
				onCancel();
				getNews();
				setLoading(false);
			} else {
				setLoading(true);
				await service.callApi(
					'insertOne',
					{
						collection: 'news',
						document: {
							...initValue,
							uniqueID: Math.floor(Math.random() * 10000000000000000),
							images,
							content: index.content,
							description: index.description,
							createdAt: new Date(),
							updatedAt: new Date(),
							slug: toSlug(initValue.title) + '-' + Math.floor(Math.random() * 10000000000000),
						},
					},
					props.token
				);

				openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
				onCancel();
				getNews();
				setLoading(false);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};
	const handleRemoveImage = async (img) => {
		try {
			await axios.post('/api/destroy', { public_id: img.response.public_id });
		} catch (error) {
			console.log(error);
		}
	};
	const onCancel = () => {
		setOpen(false);
		refMode.current = '';
		formInstance?.resetFields();
		setInitValue(defaultData);
		setIndex(defaultData);
	};

	function uploadPlugin(editor) {
		editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}

	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise(async (resolve, reject) => {
					try {
						const file = await loader.file;
						if (file) {
							const formData = new FormData();
							formData.append('file', file);
							formData.append('upload_preset', 'kx4mzert');
							fetch('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/upload', {
								method: 'POST',
								body: formData,
								redirect: 'follow',
							})
								.then((res) => res.json())
								.then((data) => {
									resolve({
										default: data.url,
									});
									console.log(data);
									message.success('upload successfully.');
								})
								.catch(() => {
									message.error('upload failed.');
								});
						}
					} catch (error) {
						reject('Error');
					}
				});
			},
			abort: () => {},
		};
	}

	const handleUpload = () => {
		const formData = new FormData();
		formData.append('file', fileList[0]);
		// formData.append('api_key', '525413597814261');
		formData.append('upload_preset', 'kx4mzert');
		setUploading(true);
		// You can use any AJAX library you like
		fetch('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/upload', {
			method: 'POST',
			body: formData,
			redirect: 'follow',
		})
			.then((res) => res.json())
			.then((data) => {
				setFileList([]);
				setImages({
					url: data.url,
					public_id: data.public_id,
				});
				console.log(data);
				message.success('upload successfully.');
			})
			.catch(() => {
				message.error('upload failed.');
			})
			.finally(() => {
				setUploading(false);
			});
	};
	const prop = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			setFileList([...fileList, file]);
			return false;
		},
		fileList,
	};
	try {
		return (
			<>
				{contextHolder}
				<SH_Model
					open={open}
					handleSubmit={handleSubmit}
					onCancel={onCancel}
					textOk={refMode.current === Constant.crud.create ? 'Tạo mới' : 'Cập nhật'}
					loading={loading}
					title={refMode.current === Constant.crud.create ? 'Tạo mới tin tức' : 'Cập nhật tin tức'}
				>
					<label>Tên</label>
					<Input
						value={initValue.title}
						onChange={(e) => setInitValue((prev) => ({ ...prev, title: e.target.value }))}
					/>

					<label>Mô tả</label>
					<CKEditor
						editor={ClassicEditor}
						data={index?.description}
						config={{
							extraPlugins: [uploadPlugin],
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							setIndex((prev) => ({ ...prev, description: data }));
						}}
					/>
					<label>Nội dung</label>
					<CKEditor
						editor={ClassicEditor}
						data={index?.content}
						config={{
							extraPlugins: [uploadPlugin],
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							setIndex((prev) => ({ ...prev, content: data }));
						}}
					/>

					<Flex align="end">
						<div className="flex flex-col">
							<label>Ảnh</label>
							<Upload {...prop}>
								<Button icon={<UploadOutlined />}>Chọn ảnh</Button>
							</Upload>
						</div>
						<Button
							type="primary"
							onClick={handleUpload}
							disabled={fileList.length === 0}
							loading={uploading}
							style={{
								marginLeft: 16,
							}}
						>
							{uploading ? 'Đang tải' : 'Tải lên'}
						</Button>
					</Flex>

					<Flex align="center">
						<Image width={200} src={images.url} />
					</Flex>
				</SH_Model>
			</>
		);
	} catch (error) {
		console.log(error);
	}
}

export default forwardRef(SH_news_detail);
