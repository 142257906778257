import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Space, Table } from 'antd';
import service from '../../../app/api/service';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import SH_Toolbar from '../components/SH_Toolbar';
import SH_user_detail from './SH_user_detail';
import Title from 'antd/lib/typography/Title';


const SH_user = (props) => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const refDetail = useRef();
	useEffect(() => {
		getUsers().then(r => r);
	}, []);
	const getUsers = async () => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'users',
				projection: {},
			})
			.then((res) => {
				setUsers(res.data.documents);
				setLoading(false);
			});
	};
	const columns = [
		{
			title: 'Tên',
			dataIndex: 'name',
			key: 'name',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{createdAt}</span>,
		},
		{
			title: 'Vai trò',
			dataIndex: 'role',
			key: 'role',
			render: (data) => <span>{data.role === 1 ? 'Admin' : 'User'}</span>,

		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => refDetail.current.update(record.name)} icon={<EditOutlined />}>Sửa</Button>
					{record.role !== 1 && <Button onClick={() => conFirm(record)} type="dashed" danger
												  icon={<DeleteOutlined />}>Xoá</Button>}
				</Space>
			),
		},
	];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa',
			content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ',
			onOk: () => {
				deleteItem(event);
			},
			footer: (_, { OkBtn, CancelBtn }) => (
				<>
					<CancelBtn />
					<OkBtn />
				</>
			),
		});
	};
	const deleteItem = (event) => {
		setLoading(true);
		service
			.callApi('deleteOne', {
				collection: 'users',
				filter: { 'name': event.name },
			})
			.then((res) => {
				setLoading(false);
				getUsers().then(r => r);
			});
	};
	const showModal = () => {
		refDetail.current.create();
	};
	return (
		<>
			<Title level={2}>Người dùng</Title>
			<SH_Toolbar left={() => <Button type="primary" onClick={showModal}>
				Thêm
			</Button>} />
			<Table pagination={true} className="mt-2" loading={loading} columns={columns} dataSource={users} />
			<SH_user_detail ref={refDetail} users={users} />
		</>
	);
};
export default SH_user;