import React from 'react';
import { dataCategoryLeft } from '../../../../data/dataSubmenu';
import { Link } from 'react-router-dom';

Submenu.propTypes = {};


function Submenu(props) {
	return (

		<ul className="flex text-[16px]">
			{dataCategoryLeft.map((item) => (
				<li className="font-family-great-viber mx-2 text-white uppercase" key={item.id}>
					<Link to={item.to}>
						{item.title}
					</Link>
				</li>
			))}
		</ul>
	);
}

export default Submenu;
