import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Col, DatePicker, Form, message, Modal, notification, Row, Select, Space, Table } from 'antd';
import axios from 'axios';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import service from '../../../app/api/service';
import Constant from '../../../lib/constant';
import SH_Model from '../../../Admin/Components/components/SH_Model';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';

const defaultData = {
	checkin: '',
	checkout: '',
	check: true,
	detail: [{
		key: 0, platform: '', description: '', note: '', note_admin: '',
	}],
};


function SH_time_keeping_detail(props, ref) {
	const { getTimeKeeping, timeKeepingList } = props;
	const [formInstance, setFormInstance] = useState();
	const [open, setOpen] = useState(false);
	const [index, setIndex] = useState(defaultData);
	const [form] = Form.useForm();
	const refMode = useRef();
	const [timeKeeping, setTimeKeeping] = useState(defaultData);
	const [api, contextHolder] = notification.useNotification();
	const user = JSON.parse(localStorage.getItem('user'));
	const [loading, setLoading] = useState(false);
	const openNotificationWithIcon = (type, message, description) => {
		api[type]({
			message, description,
		});
	};
	useImperativeHandle(ref, () => ({
		create: () => {
			setOpen(true);
			refMode.current = Constant.crud.create;
		}, update: (uniqueID) => {
			service
				.callApi('findOne', {
					collection: 'timeKeeping', filter: {
						uniqueID,
					},
				})
				.then((res) => {
					const { _id, ...remain } = res.data.document;
					setTimeKeeping(remain);
					setIndex(res.data.document);
					setOpen(true);
					refMode.current = Constant.crud.update;
				});
		},
	}));
	useEffect(() => {
		onFormInstanceReady(form);
	}, []);
	const onFormInstanceReady = (instance) => {
		setFormInstance(instance);
	};
	const handleSubmit = async () => {
		try {
			if (refMode.current === Constant.crud.update) {
				setLoading(true);
				await service.callApi('updateOne', {
					collection: 'timeKeeping', filter: {
						uniqueID: timeKeeping.uniqueID,
					}, update: {
						$set: {
							...timeKeeping,
						},
					},
				}, props.token);
				openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
				onCancel();
				getTimeKeeping();
				setLoading(false);
			} else {
				const _date = timeKeeping.date.split('/');
				if (timeKeepingList?.find(time => (time.date).split('/')[0] === (_date[0]) && (time.date).split('/')[1] === (_date[1]) && (time.date).split('/')[2] === (_date[2]))) {
					openNotificationWithIcon('error', 'Thất bại', 'Ngày này đã được chấm công');
					return;
				}
				setLoading(true);
				await service.callApi('insertOne', {
					collection: 'timeKeeping', document: {
						...timeKeeping,
						uniqueID: Math.floor(Math.random() * 10000000000000000),
						createdAt: new Date(),
						updatedAt: new Date(),
						userId: user._id,
					},
				}, props.token);
				openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
				onCancel();
				getTimeKeeping();
				setLoading(false);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};
	const handleRemoveImage = async (img) => {
		try {
			await axios.post('/api/destroy', { public_id: img.response.public_id });
		} catch (error) {
			console.log(error);
		}
	};
	const onCancel = () => {
		setOpen(false);
		refMode.current = '';
		formInstance?.resetFields();
		setTimeKeeping(defaultData);
		setIndex(defaultData);
	};

	function uploadPlugin(editor) {
		editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}

	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise(async (resolve, reject) => {
					try {
						const file = await loader.file;
						if (file) {
							const formData = new FormData();
							formData.append('file', file);
							formData.append('upload_preset', 'kx4mzert');
							fetch('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/upload', {
								method: 'POST', body: formData, redirect: 'follow',
							})
								.then((res) => res.json())
								.then((data) => {
									resolve({
										default: data.url,
									});
									message.success('upload successfully.');
								})
								.catch(() => {
									message.error('upload failed.');
								});
						}
					} catch (error) {
						reject('Error');
					}
				});
			}, abort: () => {
			},
		};
	}

	const applyChange = (prop, index, value) => {
		const _data = structuredClone(timeKeeping);
		console.log({ _data, prop, index, value });
		_data.detail[index][prop] = value;
		setTimeKeeping(_data);
	};
	const columns = [
		{
			title: 'Nền tảng',
			dataIndex: 'platform',
			width: 300,
			key: 'platform', render: (_, record) =>
				<Select
					size="large"
					className="w-full"
					value={timeKeeping.detail[record.key]?.platform}
					onChange={(e) => applyChange('platform', record.key, e)}>
					{Constant.platform.map(item => <Select.Option value={item.code}>{item.name}</Select.Option>)}
				</Select>,
		},
		{
			title: 'Mô tả chi tiết',
			dataIndex: 'description',
			width: 300,
			key: 'description',
			render: (_, record) =>
				<CKEditor
					editor={ClassicEditor}
					data={timeKeeping?.detail[record.key]?.description}

					config={{
						extraPlugins: [uploadPlugin],
					}}
					onChange={(event, editor) => {
						const data = editor.getData();
						applyChange('description', record.key, data);
					}}
				/>,
		}, {
			title: 'Ghi chú',
			dataIndex: 'note',
			width: 255,
			key: 'note',
			render: (_, record) => <TextArea size="large" value={timeKeeping.detail[record.key]?.note}
											 onChange={(e) => applyChange('note', record.key, e.target.value)} />,
		},
		{
			title: 'Ghi chú của quản lý',
			dataIndex: 'note',
			width: 255,
			key: 'note_admin',
			render: (_, record) => <TextArea size="large" value={timeKeeping.detail[record.key]?.note_admin}
											 onChange={(e) => applyChange('note_admin', record.key, e.target.value)} />,
		},
		{
			title: 'Thao tác', key: 'action',
			render: (_, record) => (<Space size="middle">
				<Button onClick={() => conFirm(record)} type="dashed" danger icon={<DeleteOutlined />}>
					Xoá
				</Button>
			</Space>),
		}];
	const conFirm = (event) => {
		return Modal.confirm({
			title: 'Xác nhận xóa', content: 'Bạn có chắc chắn muốn xóa bản ghi này không ? ', onOk: () => {
				deleteItem(event);
			}, footer: (_, { OkBtn, CancelBtn }) => (<>
				<CancelBtn />
				<OkBtn />
			</>),
		});
	};
	const deleteItem = (event) => {
		const _timekeeping = structuredClone(timeKeeping);
		_timekeeping.detail = _timekeeping.detail.filter(item => item.key !== event.key);
		_timekeeping.detail.forEach((item, index) => {

		});
		setTimeKeeping(_timekeeping);
	};
	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};
	console.log({ timeKeeping });
	try {
		return (<>
			{contextHolder}
			<SH_Model
				width={1400}
				open={open}
				handleSubmit={handleSubmit}
				onCancel={onCancel}
				textOk={refMode.current === Constant.crud.create ? 'Tạo mới' : 'Cập nhật'}
				loading={loading}
				title={refMode.current === Constant.crud.create ? 'Tạo mới chấm công' : 'Cập nhật chấm công'}
			>
				<Row>
					<Col span={8}>
						<label className="font-bold text-red-600">Số giờ làm việc tiêu chuẩn
							: <b>7.5
							</b></label>

					</Col>
				</Row>
				<Row>
					<Col span={8}>
						<label className="font-bold text-red-600">Số giờ làm việc thực tế
							: <b>{
								timeKeeping.workingHour > 0 ? timeKeeping.workingHour : '--:--'}
							</b></label>

					</Col>
				</Row>
				<Row className="mt-2">

					<Col span={8}>
						<label className="mr-2">Ngày : <b>{timeKeeping.date}</b></label>
						<DatePicker size="large"
									disabledDate={disabledDate}
									picker="date" onChange={(value) => setTimeKeeping(prevState => ({
							...prevState,
							date: dayjs(value).format('DD/MM/YYYY'),
							month: dayjs(value).format('MM'),
							year: dayjs(value).format('YYYY'),
						}))} />
					</Col>
					<Col span={8}>
						<label className="mr-2">Giờ checkin : <b>{timeKeeping?.checkin}</b></label>
						<DatePicker picker="time"
									size="large"
									onChange={(value) => setTimeKeeping(prevState => ({
										...prevState,
										checkin: dayjs(value).format('HH:mm'),
										workingHour: ((Number(Number(timeKeeping.checkout.split(':')[0]) - Number(dayjs(value).format('HH:mm').split(':')[0])) +
											((Number(timeKeeping.checkout.split(':')[1]) - Number(dayjs(value).format('HH:mm').split(':')[1])) / 60)) - 1.5)?.toFixed(1),

									}))} />
					</Col>
					<Col span={8}>
						<label className="mr-2">Giờ checkout : <b>{timeKeeping?.checkout}</b></label>
						<DatePicker size="large"

									picker="time"
									onChange={(value) => setTimeKeeping(prevState => ({
										...prevState,
										checkout: dayjs(value).format('HH:mm'),
										workingHour: ((Number(Number(dayjs(value).format('HH:mm').split(':')[0]) - Number(timeKeeping.checkin.split(':')[0])) +
											((Number(dayjs(value).format('HH:mm').split(':')[1]) - Number(timeKeeping.checkin.split(':')[1])) / 60)) - 1.5)?.toFixed(1),
									}))} />
					</Col>

				</Row>
				<Row>
					<Col span={24}>
						<label>Ghi chú của quản lý</label>
						<TextArea size="small" value={timeKeeping?.admin_note}
								  onChange={e => setTimeKeeping(prevState => ({
									  ...prevState,
									  admin_note: e.target.value,
								  }))} />
					</Col>
				</Row>
				<label className="font-bold mt-3">Chi tiết</label>
				<div className="flex">
					<Button icon={<PlusOutlined />} type="primary" onClick={() => setTimeKeeping(prevState => ({
						...prevState,
						detail: [...prevState.detail, {
							platform: '',
							key: prevState.detail[prevState.detail.length - 1].key + 1,
							description: '',
							note: '',
						}],
					}))}>
						Thêm bản ghi
					</Button>
				</div>
				<Table pagination={false} className="mt-2" loading={loading} columns={columns}
					   dataSource={timeKeeping.detail} />
			</SH_Model>
		</>)
			;
	} catch (error) {
		console.log(error);
	}
}

export default forwardRef(SH_time_keeping_detail);
