import { default as React, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import * as Realm from 'realm-web';
import { PieChartOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import SH_time_keeping from './pages/time-keeping';
import DashBoard from '../Admin/Components/Darshboard';
import SH_header_admin from '../Admin/Components/Header';
import DashBoard_Member from './pages';
import SH_user_profile from '../components/profile';

const { Header, Content, Footer, Sider } = Layout;

Member.propTypes = {};

function Member(props) {
	const { url } = useRouteMatch();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const [collapsed] = useState(false);
	useEffect(() => {
		getToken().then(r => r);
	}, []);
	const getToken = async () => {
		const app = new Realm.App({ id: 'data-ywubf' });

		async function loginEmailPassword(email, password) {
			const credentials = Realm.Credentials.emailPassword(email, password);
			return await app.logIn(credentials);
		}

		const user = await loginEmailPassword('sunhaircompany', 'nRye6PfMkJ6XOcMO');
		localStorage.setItem('accessToken', user.accessToken);
	};
	return (
		<Layout trigger={null} collapsible collapsed={collapsed}>
			<Header style={{
				position: 'sticky',
				top: 0,
				zIndex: 1,
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
				<SH_header_admin />
			</Header>

			<Layout style={{ marginLeft: 200 }} className="mt-4">
				<Sider
					className="mt-4"
					style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
				>
					<div className="demo-logo-vertical" />
					<Menu theme="dark" mode="inline"
						  className="mt-10"
						  onClick={e => history.push(`/member/${e.key}`)}
						  items={[
							  {
								  key: '',
								  label: 'Tổng quan',
								  icon: <PieChartOutlined />,

							  },
							  {
								  key: 'time-keeping',
								  label: 'Chấm công',
								  icon: <PieChartOutlined />,

							  },
						  ]} />
				</Sider>
				<Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
					<Switch>
						<Route path={`${url}/time-keeping`} component={SH_time_keeping} />
						<Route path={`${url}/user-profile`} component={SH_user_profile} />
						<Route path={`${url}`} component={DashBoard}>
							<DashBoard_Member paymentCheckOut={[]} />
						</Route>
					</Switch>
				</Content>

			</Layout>
		</Layout>
	);
}

export default Member;
