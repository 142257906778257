import React from 'react';
import { Button } from 'antd';
import Constant from '../../../lib/constant';

WhatAppButton.propTypes = {};

function WhatAppButton(props) {
	return (

		<div
			className="flex flex-col content-center buttons-social bg-white rounded border border-solid border-[#ccc] p-3"
			style={{
				position: 'fixed',
				bottom: '30%',
				right: '0px',
			}}>
			{Constant.socials.map((item, index) => <>
					<Button
						key={index}
						style={{ width: '50px', height: '50px', overflow: 'hidden' }}
						target="_blank"
						href={item.href}
						icon={item.icon}
						type="text"

					>
					</Button>

				</>,
			)}</div>
	);

}

export default WhatAppButton;
