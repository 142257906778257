import ProviderContext from '../src/app/ContextGlobal/index';
import Index from './features';

function App() {
	return (
		<div className="App">
			<ProviderContext>
				<Index/>
			</ProviderContext>
		</div>
	);
}

export default App;
