import React, { useEffect, useState } from 'react';
import { Button, Flex, Input, message } from 'antd';
import service from '../../../app/api/service';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import TextArea from 'antd/es/input/TextArea';


const SH_video_introduce = (props) => {

	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState({});
	const [isEditTitle, setIsEditTitle] = useState(false);
	useEffect(() => {
		getTitle();
	}, []);

	const getTitle = () => {
		setLoading(true);
		service
			.callApi('findOne', {
				collection: 'titles',
				projection: {},
				filter: {
					component: 'video-introduce-home',
				},
			})
			.then((res) => {
				const { _id, ...remain } = res.data.document;
				setTitle(remain);
				setLoading(false);
			});
	};
	console.log(title);

	const saveTitle = () => {
		service
			.callApi('updateOne', {
				collection: 'titles',
				filter: {
					'component': 'video-introduce-home',
				},
				update: {
					'$set': {
						...title,
					},
				},
			})
			.then(() => {
				message.success('Thao tác thành công');
				setIsEditTitle(false);
			});
	};
	console.log(isEditTitle);
	return (
		<>
			<Title level={2}>
				<span className={`flex align-items-center text-[${title?.color}]`}>{title?.name} <Button
					className="border-0 bg-gray-100"
					onClick={() => {
						if (isEditTitle) {
							saveTitle();
						} else setIsEditTitle(true);
					}}>{isEditTitle ?
					<SaveOutlined /> : <EditOutlined />} </Button>

				</span>
			</Title>
			<Flex justify="center" gap="middle" className="mb-2">
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Tiêu đề</label>
					<Input
						disabled={!isEditTitle}
						value={title?.name}
						onChange={(e) => setTitle((prev) => ({ ...prev, name: e.target.value }))}
					/>
				</Flex>
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Màu chữ</label>
					<Input
						disabled={!isEditTitle}
						value={title?.color}
						onChange={(e) => setTitle((prev) => ({ ...prev, color: e.target.value }))}
					/>
				</Flex>

			</Flex>
			<Flex className="flex-col">
				<label className="font-bold">Embed Video</label>
				<TextArea
					style={{ height: '599px' }}
					size="large"
					disabled={!isEditTitle}
					value={title?.embed}
					onChange={(e) => setTitle((prev) => ({ ...prev, embed: e.target.value }))}
				/>
			</Flex>
		</>
	);
};
export default SH_video_introduce;