import axiosClient from './axiosClient';

const service = {
	callApi: (action = 'find', props, token = localStorage.getItem('accessToken')) => {
		const data = JSON.stringify({
			database: 'test',
			dataSource: 'Cluster0',
			...props,
		});
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Request-Headers': '*',
				Authorization: `Bearer ${token}`,
			},
			data,
		};

		return axiosClient.post(`${action}`, data, config);
	},
	uploadFile: (body) => {
		return axiosClient.post('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/upload', {
			...body,
			upload_preset: 'kx4mzert',
		});
	},
};
export default service;
