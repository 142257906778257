import React from 'react';

const Constant = {
		crud: {
			create: 'CREATE',
			update: 'UPDATE',
			delete: 'DELETE',
		},
		platform: [
			{ code: 'Instagram', name: 'Instagram' },
			{ code: 'Facebook', name: 'Facebook' },
			{ code: 'Tiktok', name: 'Tiktok' },
			{ code: 'Youtube', name: 'Youtube' },
			{ code: 'Google', name: 'Google' },
			{ code: 'Zalo', name: 'Zalo' },
			{ code: 'X', name: 'X' },
			{ code: 'Pinterest', name: 'Pinterest' },
			{ code: 'Thread', name: 'Thread' },
			{ code: 'Alibaba', name: 'Alibaba' },
			{ code: 'Other', name: 'Khác' },
		],
		socials: [
			{
				icon: <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50"
						   viewBox="0 0 48 48">
					<radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899"
									gradientUnits="userSpaceOnUse">
						<stop offset="0" stopColor="#fd5"></stop>
						<stop offset=".328" stopColor="#ff543f"></stop>
						<stop offset=".348" stopColor="#fc5245"></stop>
						<stop offset=".504" stopColor="#e64771"></stop>
						<stop offset=".643" stopColor="#d53e91"></stop>
						<stop offset=".761" stopColor="#cc39a4"></stop>
						<stop offset=".841" stopColor="#c837ab"></stop>
					</radialGradient>
					<path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
						  d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path>
					<radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813"
									gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse">
						<stop offset="0" stopColor="#4168c9"></stop>
						<stop offset=".999" stopColor="#4168c9" stopOpacity="0"></stop>
					</radialGradient>
					<path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
						  d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path>
					<path fill="#fff"
						  d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path>
					<circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle>
					<path fill="#fff"
						  d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
				</svg>,
				href: 'https://www.instagram.com/sunhaircompany_official/',
			},
			{
				icon: <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
					<linearGradient id="CXanuwD9EGkBgTn76_1mxa_p62ASPK2Kpqp_gr1" x1="9.993" x2="40.615" y1="-299.993"
									y2="-330.615" gradientTransform="matrix(1 0 0 -1 0 -290)"
									gradientUnits="userSpaceOnUse">
						<stop offset="0" stopColor="#2aa4f4"></stop>
						<stop offset="1" stopColor="#007ad9"></stop>
					</linearGradient>
					<path fill="url(#CXanuwD9EGkBgTn76_1mxa_p62ASPK2Kpqp_gr1)"
						  d="M24,4C12.954,4,4,12.954,4,24c0,10.028,7.379,18.331,17.004,19.777	C21.981,43.924,22.982,41,24,41c0.919,0,1.824,2.938,2.711,2.818C36.475,42.495,44,34.127,44,24C44,12.954,35.046,4,24,4z"></path>
					<path
						d="M27.707,21.169c0-1.424,0.305-3.121,1.757-3.121h4.283l-0.001-5.617l-0.05-0.852l-0.846-0.114	c-0.608-0.082-1.873-0.253-4.206-0.253c-5.569,0-8.636,3.315-8.636,9.334v2.498H15.06v7.258h4.948V43.6	C21.298,43.861,22.633,44,24,44c1.268,0,2.504-0.131,3.707-0.357V30.301h5.033l1.122-7.258h-6.155V21.169z"
						opacity=".05"></path>
					<path
						d="M27.207,21.169c0-1.353,0.293-3.621,2.257-3.621h3.783V12.46l-0.026-0.44l-0.433-0.059	c-0.597-0.081-1.838-0.249-4.143-0.249c-5.323,0-8.136,3.055-8.136,8.834v2.998H15.56v6.258h4.948v13.874	C21.644,43.876,22.806,44,24,44c1.094,0,2.16-0.112,3.207-0.281V29.801h5.104l0.967-6.258h-6.072V21.169z"
						opacity=".05"></path>
					<path fill="#fff"
						  d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46	c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.475	C21.988,43.923,22.981,44,24,44c0.921,0,1.82-0.062,2.707-0.182V29.301z"></path>
				</svg>,
				href: 'https://www.facebook.com/SunHairCompany',
			},
			{
				icon: <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
					<linearGradient id="PgB_UHa29h0TpFV_moJI9a_9a46bTk3awwI_gr1" x1="9.816" x2="41.246" y1="9.871"
									y2="41.301" gradientUnits="userSpaceOnUse">
						<stop offset="0" stopColor="#f44f5a"></stop>
						<stop offset=".443" stopColor="#ee3d4a"></stop>
						<stop offset="1" stopColor="#e52030"></stop>
					</linearGradient>
					<path fill="url(#PgB_UHa29h0TpFV_moJI9a_9a46bTk3awwI_gr1)"
						  d="M45.012,34.56c-0.439,2.24-2.304,3.947-4.608,4.267C36.783,39.36,30.748,40,23.945,40	c-6.693,0-12.728-0.64-16.459-1.173c-2.304-0.32-4.17-2.027-4.608-4.267C2.439,32.107,2,28.48,2,24s0.439-8.107,0.878-10.56	c0.439-2.24,2.304-3.947,4.608-4.267C11.107,8.64,17.142,8,23.945,8s12.728,0.64,16.459,1.173c2.304,0.32,4.17,2.027,4.608,4.267	C45.451,15.893,46,19.52,46,24C45.89,28.48,45.451,32.107,45.012,34.56z"></path>
					<path
						d="M32.352,22.44l-11.436-7.624c-0.577-0.385-1.314-0.421-1.925-0.093C18.38,15.05,18,15.683,18,16.376	v15.248c0,0.693,0.38,1.327,0.991,1.654c0.278,0.149,0.581,0.222,0.884,0.222c0.364,0,0.726-0.106,1.04-0.315l11.436-7.624	c0.523-0.349,0.835-0.932,0.835-1.56C33.187,23.372,32.874,22.789,32.352,22.44z"
						opacity=".05"></path>
					<path
						d="M20.681,15.237l10.79,7.194c0.689,0.495,1.153,0.938,1.153,1.513c0,0.575-0.224,0.976-0.715,1.334	c-0.371,0.27-11.045,7.364-11.045,7.364c-0.901,0.604-2.364,0.476-2.364-1.499V16.744C18.5,14.739,20.084,14.839,20.681,15.237z"
						opacity=".07"></path>
					<path fill="#fff"
						  d="M19,31.568V16.433c0-0.743,0.828-1.187,1.447-0.774l11.352,7.568c0.553,0.368,0.553,1.18,0,1.549	l-11.352,7.568C19.828,32.755,19,32.312,19,31.568z"></path>
				</svg>,
				href: 'https://www.youtube.com/@sunhaircompanyofficial',
			},
			{
				icon: <img src="https://res.cloudinary.com/dfjgtdsbf/image/upload/v1720175311/pngwing.com_kaooea.png"
						   alt="alibaba" className="w-[50px]" />,
				href: 'http://sunhaircompany.trustpass.alibaba.com/',
			},
		],
	}
;
export default Constant;