import { Box, Grid, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import FB from './listFB';
import service from '../../../../app/api/service';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 6,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 4,
		slidesToSlide: 3, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};
FeedBack.propTypes = {};

function FeedBack(props) {
	const [feedBacks, setFeedBacks] = useState([]);
	const { title = {} } = props;
	useEffect(() => {
		loadData();
	}, []);
	const loadData = async () => {
		service
			.callApi(
				'find',
				{
					collection: 'feedbacks',
					projection: {},
				},
				props.token,
			)
			.then((res) => {
				setFeedBacks(res.data.documents);
			});
	};

	return (
		<Paper elevation={0} style={{ marginTop: '20px', textAlign: 'center' }}>
			<Grid item style={{ padding: '20px' }}>
				<h3 className={`subHead font-family-great-viber text-[${title?.color}]`}>{title?.name}</h3>
				<Box>
					<Carousel
						responsive={responsive}
						containerClass="carousel-container"
						removeArrowOnDeviceType={['tablet', 'mobile']}
						itemClass="carousel-item-padding-40-px"
						infinite={true}
						transitionDuration={1000}
					>
						{feedBacks?.map((item) => (
							<FB product={item} key={item._id} />
						))}
					</Carousel>
				</Box>
			</Grid>
		</Paper>
	);
}

export default FeedBack;
