import React from 'react';

AboutUs.propTypes = {};

function AboutUs(props) {
	return (
		<div
			className="container-about py-[70px] w-[95.625%] ml-auto mt-[12.625vw] max-md:w-[91.25%] max-md:mr-auto max-md:ml-auto max-md:mt-[29.6vw]">
			<div
				className="relative home-about-us grid gap-[0.625vw] max-md:w-[100%] max-md:pb-0 ml-auto max-md:mt-[8vw]">
				<div className="left absolute w-[32.5625vw] max-md:w-[100%] max-md:relative z-20"><h2
					className="sub-title text-[1.125vw] leading-normal tracking-[0.12em] uppercase font-bold text-dateNews max-md:text-[3.73vw] aos-init aos-animate"
					data-aos-once="true" data-aos="fade-right" data-aos-duration="2000">About Us</h2>
					<h3 className="block desc text-[2.875vw] leading-[1.22] tracking-[-1.5px] max-lg:tracking-[-0.5px] max-md:text-[6.66vw] w-[85%] aos-init aos-animate"
						data-aos-once="true" data-aos="fade-right" data-aos-duration="2000">
						<p><strong>
							Offshore Energy
							Installation
						</strong></p>
						<p>Your&nbsp; trustful &amp; reliable partner for <strong>Hair Extension
						</strong> and <strong> &amp; Hair</strong> industry</p>
						<div className="ddict_btn top-[112px] left-[477.609px]"></div>
					</h3>
					<a className="button-with-mousemove relative flex justify-center items-center w-[8.9375vw] h-[8.9375vw] rounded-full text-[1.125vw] font-bold leading-[2] mt-[3.75vw] uppercase bg-member text-[#fff] max-md:w-[26.66vw] max-md:mt-[6.4vw] max-md:h-[26.66vw] max-md:text-[3.73vw]"
					   href="/about-us/introduce">
						<div>See More</div>
					</a></div>
				<div className="right w-[50%] grid gap-[0.625vw] max-md:w-[100%] max-md:pb-0 ml-auto max-md:mt-[8vw]">
					<div className="grid grid-cols-2 gap-1">
						<div className="col-span-2">
							<img className="w-full" alt="about-us-image"
								 src="https://res.cloudinary.com/dfjgtdsbf/image/upload/c_crop,w_670/v1706344970/ab_ppa1lq.png" />

						</div>
						<div className="h-[265px]">
							<img className="w-full h-full" alt="about-us-image"
								 src="https://res.cloudinary.com/dfjgtdsbf/image/upload/v1712287207/sunhair/ncwlsaqxtthzgfpf9udc.jpg" />
						</div>
						<div className="h-[265px]">
							<img className="w-full h-full" alt="about-us-image"
								 src="https://res.cloudinary.com/dfjgtdsbf/image/upload/v1706697254/sunhair/tzkndzbgyaecltydmyaa.jpg" />
						</div>
					</div>
				</div>
			</div>
		</div>

	)
		;
}

export default AboutUs;
