import React from 'react';
import PropTypes from 'prop-types';

SH_Toolbar.propTypes = {
	left: PropTypes.func,
	center: PropTypes.func,
	right: PropTypes.func,
};

function SH_Toolbar(props) {
	const {
		left = () => {
		}, right = () => {
		}, center = () => {
		},
	} = props;
	return (
		<div className="bg-white p-2 flex align-items-center justify-between">
			{left()}
			{center()}
			{right()}
		</div>
	);
}

export default SH_Toolbar;