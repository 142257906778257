import Fab from '@material-ui/core/Fab';
import React, { useEffect, useState } from 'react';
import WhatAppButton from './whatApp';
import './style.css';

export default function BackToTop(props) {
	const [pageYOffset, setPageYOffset] = useState(window.pageYOffset);
	const handleClick = (event) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	useEffect(() => {
		const interId = setInterval(() => {
			setPageYOffset(window.pageYOffset);
			console.log(window.pageYOffset)
		}, 1000);
		return clearInterval(interId);
	});
	console.log(pageYOffset);
	return (
		<React.Fragment>
			{pageYOffset > 3000 &&
				<div style={{ position: 'fixed', right: '50px', bottom: '50px' }} onClick={handleClick}>
					<Fab
						color="default"
						size="medium"
						style={{ borderRadius: '0', border: '1px solid ', background: 'white' }}
						aria-label="scroll back to top"
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path
								d="M5 10.6667L12 4M12 4L19 10.6667M12 4L12 20"
								stroke="black"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							></path>
						</svg>
					</Fab>
				</div>}

			<WhatAppButton />
		</React.Fragment>
	);
}
