import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import service from '../../../../app/api/service';
import ProductItem from '../../Products/product';

NewProduct.propTypes = {};
const useStyles = makeStyles((theme) => ({
	width: {
		[theme.breakpoints.up('sm')]: {
			width: '20%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '25%',
		},
		[theme.breakpoints.down('md')]: {
			width: '33%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
}));

function NewProduct(props) {
	const { title } = props;
	const [products, setProducts] = useState([]);
	const classes = useStyles();
	useEffect(() => {
		loadData().then(r => r);
	}, []);
	const loadData = async () => {
		service
			.callApi(
				'find',
				{
					collection: 'products',
					projection: {},
				},
				props.token,
			)
			.then((res) => {
				setProducts(res.data.documents);
			});
	};

	return (
		<Paper elevation={0} style={{ marginTop: '20px', textAlign: 'center' }}>
			<Grid item style={{ padding: '20px' }}>
				<h2 className={`subHead font-family-great-viber text-[${title?.color}]`}>{title?.name}</h2>
				<Box>
					<Grid container spacing={2}>
						{products.map((product) => (
							<Grid className={classes.width} key={product._id} item xs={12} sm={4} md={3} lg={2}>
								<ProductItem product={product} />
							</Grid>
						))}
					</Grid>
				</Box>
			</Grid>
		</Paper>
	);
}

export default NewProduct;
