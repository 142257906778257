import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';

SH_Model.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.string,
	onCancel: PropTypes.func,
	loading: PropTypes.bool,
	handleSubmit: PropTypes.func,
	children: PropTypes.element,
	textOk: PropTypes.string,
	width: PropTypes.number,
	heights: PropTypes.number,
};

function SH_Model(props) {
	const { open, title, loading, handleSubmit, children, onCancel, textOk, width, height } = props;
	return (
		<Modal
			{...props}
			open={open}
			title={title}
			centered
			width={width ? width : 1000}
			okButtonProps={{
				autoFocus: true,
			}}
			onCancel={onCancel}
			destroyOnClose

			footer={[
				<Button
					icon={<CloseCircleOutlined />}
					key="link"
					type="primary"
					danger
					loading={loading}
					onClick={onCancel}
				>
					Hủy
				</Button>,

				<Button icon={<SaveOutlined />} key="submit" type="primary" loading={loading} onClick={handleSubmit}>
					{textOk}
				</Button>,
			]}
		>
			{children.map((x) => x)}
		</Modal>
	);
}

export default SH_Model;
