import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import './style.css';
import service from '../../../../app/api/service';

MainProduct.propTypes = {};

function MainProduct(props) {
	const { token, title = {} } = props;
	const [categories, setCategories] = useState([]);
	useEffect(() => {
		getCategory().then(r => r);
	}, []);
	const getCategory = async () => {
		service
			.callApi('find', {
				collection: 'categories',
				projection: {},

			}, token)
			.then((res) => {
				setCategories(res.data.documents);
			});
	};
	return (
		<Paper
			elevation={0}
			style={{ marginTop: '20px', padding: '20px', border: '1px solid #edeef5', textAlign: 'center' }}
		>
			<h2 className={`subHead font-family-great-viber text-[${title?.color}]`}>{title?.name}</h2>

			<div className="main-product">
				<Grid container spacing={2}>
					{categories.map((cate, index) => (
						<Grid className="item" key={index} item xs={12} sm={4} md={3} lg={3}>
							<img src={cate.images.url} alt="img category" />
							<p className="font-bold text-black font-family-great-viber">{cate.name}</p>
						</Grid>
					))}
				</Grid>
			</div>
		</Paper>
	);
}

export default MainProduct;
