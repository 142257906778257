import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Flex, Input, Modal, Select, Space, Table } from 'antd';
import { EditOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';
import SH_Toolbar from '../../../Admin/Components/components/SH_Toolbar';
import SH_time_keeping_detail from './SH_time_keeping_detail';
import service from '../../../app/api/service';
import Excel from 'exceljs';


const SH_time_keeping = (props) => {
	const [users, setUsers] = useState([]);
	const [timeKeeping, setTimeKeeping] = useState([]);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState({});
	const [isEditTitle, setIsEditTitle] = useState(false);
	const defaultFilter = {
		month: dayjs(new Date()).format('MM'),
		year: dayjs(new Date()).format('YYYY'),
	};
	const [filter, setFilter] = useState(defaultFilter);
	const refDetail = useRef();
	useEffect(() => {
		getTimeKeeping(filter);
		getUsers();
	}, []);
	const getTimeKeeping = (filter = {}) => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'timeKeeping',
				projection: {},
				filter,
			})
			.then((res) => {
				setTimeKeeping(res.data.documents?.map((x, r) => ({ ...x, STT: r + 1 })));
				setLoading(false);
			});
	};
	const getUsers = (filter = {}) => {
		setLoading(true);
		service
			.callApi('find', {
				collection: 'users',
				projection: {},
				filter,
			})
			.then((res) => {
				setUsers(res.data.documents);
				setLoading(false);
			});
	};
	const columns = [
		{
			title: 'STT',
			dataIndex: 'STT',
			key: 'STT',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Nhân viên',
			dataIndex: 'userId',
			key: 'userId',
			render: (userId) => <span>{users.find(u => u._id === userId)?.name}</span>,
		},
		{
			title: 'Ngày',
			dataIndex: 'date',
			key: 'date',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Giờ check in',
			dataIndex: 'checkin',
			key: 'checkin',
			render: (checkin) => <span className={checkin !== '08:30' && 'text-red-600 font-bold'}>{checkin}</span>,
		},
		{
			title: 'Giờ check out',
			dataIndex: 'checkout',
			key: 'checkout',
			render: (checkout) => <span className={checkout !== '17:30' && 'text-red-600 font-bold'}>{checkout}</span>,
		},
		{
			title: 'Số giờ làm việc thực tế',
			dataIndex: 'workingHour',
			key: 'workingHour',
			render: (workingHour) => <span
				className={Number(workingHour) !== 7.5 && 'text-red-600 font-bold'}>{workingHour > 0 ? workingHour : '--:--'}</span>,

		},
		{
			title: 'Ghi chú của quản lý',
			dataIndex: 'admin_note',
			key: 'admin_note',
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Ngày tạo',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => <span>{dayjs(createdAt).format('HH:mm A DD-MM-YYYY')}</span>,
		},
		{
			title: 'Thao tác',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Button
						// disabled={record.date.split('/')[0] !== dayjs(new Date()).format('DD/MM/YYYY').split('/')[0] || record.date.split('/')[1] !== dayjs(new Date()).format('DD/MM/YYYY').split('/')[1] || record.date.split('/')[2] !== dayjs(new Date()).format('DD/MM/YYYY').split('/')[2]}
						onClick={() => refDetail.current.update(record.uniqueID)} icon={<EditOutlined />}>
						Sửa
					</Button>
				</Space>
			),
		},
	];
	const showModal = () => {
		refDetail.current.create();
	};
	const exportReport = async () => {
		const workbook = new Excel.Workbook();
		const worksheet = workbook.addWorksheet('ChamCongT' + new Date().getMonth());
		// add header

		worksheet.addTable({
			name: 'MyTable',
			ref: 'A1',
			headerRow: true,
			style: {
				showRowStripes: true,
			},
			columns: [
				{ name: 'STT', filterButton: true },
				{ name: 'Nhân viên', filterButton: true },
				{ name: 'Ngày chấm công', filterButton: true },
				{ name: 'Giờ checkin', filterButton: true },
				{ name: 'Giờ checkout', filterButton: true },
				{ name: 'Giờ thực tế', filterButton: true },
				{ name: 'Ngày tạo', filterButton: true },
			],
			rows: [
				...timeKeeping.map((time, index) => ([
					index + 1,
					users.find(user => user._id === time.userId).name,
					time.date,
					time.checkin,
					time.checkout,
					time.workingHour,
					dayjs(time.createdAt).format('HH:mm A DD-MM-YYYY'),
				])),
			],
		});
		const columns = worksheet.columns;
		columns.forEach(column => {
			column.width = 25;
			column.alignment = { vertical: 'middle', horizontal: 'center' };
		});
		// add rows
		timeKeeping.forEach(r => {
			worksheet.addRow(r);
		});
		// style header
		for (let j = 0; j < timeKeeping.length; j++) {
			for (let i = 0; i < worksheet.columns.length; i++) {
				worksheet.getCell(j + 2, i + 1).style = {
					border: {
						top: { style: 'thin' },
						left: { style: 'thin' },
						bottom: { style: 'thin' },
						right: { style: 'thin' },
					},
				};

			}
		}
		for (let i = 0; i < worksheet.columns.length; i++) {
			worksheet.getCell(1, i + 1).style = {
				font: {
					bold: true,
					color: { argb: 'ffffff' },
					size: 14,
				},
				border: {
					top: { style: 'thin' },
					left: { style: 'thin' },
					bottom: { style: 'thin' },
					right: { style: 'thin' },
				},
				alignment: {
					horizontal: 'center',
					vertical: 'middle',
				},
				fill: {
					type: 'pattern',
					pattern: 'darkTrellis',
					fgColor: { argb: '4472c4' },
					bgColor: { argb: '4472c4' },
				},
			};

		}

		// export
		const fileBuffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([fileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = `${'ChamCongT' + new Date().getMonth()}.xlsx`;
		link.click();

	};
	const applyChangeSearch = (param) => {
		setFilter(param);
		if (Number(param.month) > 0) {
			getTimeKeeping(param);

		} else {
			getTimeKeeping({
				userId: param.userId,
				...defaultFilter,
			});
		}
	};
	return (
		<>
			<Title level={2}>
				<span className={`flex align-items-center`}> Chấm công

				</span>
			</Title>
			{isEditTitle && <Flex justify="center" gap="middle" className="mb-2">
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Tiêu đề</label>
					<Input
						value={title?.name}
						onChange={(e) => setTitle((prev) => ({ ...prev, name: e.target.value }))}
					/>
				</Flex>
				<Flex className="flex-col w-1/2">
					<label className="font-bold">Màu chữ</label>
					<Input

						value={title?.color}
						onChange={(e) => setTitle((prev) => ({ ...prev, color: e.target.value }))}
					/>
				</Flex>
			</Flex>}
			<SH_Toolbar
				left={() => (
					<Button icon={<PlusOutlined />} type="link" onClick={showModal}>
						Thêm
					</Button>
				)}
				right={() =>
					<Button icon={<ExportOutlined />} type="link" onClick={exportReport}>
						Export Báo cáo
					</Button>
				}
			/>
			<div className="flex justify-end mt-2 align-items-center">
				<label>Lọc theo nhân viên</label>
				<Select
					allowClear={true}
					size="middle"
					className="w-1/3 ml-2"
					onChange={(e) => applyChangeSearch({ ...filter, userId: e })}>
					{users.map(user => <Select.Option value={user._id}>{user.name}</Select.Option>)}
				</Select>
				<DatePicker onChange={e => applyChangeSearch({
					...filter,
					month: dayjs(e).format('MM'),
					year: dayjs(e).format('YYYY'),
				})} picker="month" />
			</div>
			<Table className="mt-2" loading={loading} columns={columns} bordered
				   scroll={{ y: 500 }}
				   dataSource={timeKeeping} />
			<SH_time_keeping_detail
				ref={refDetail}
				getTimeKeeping={getTimeKeeping}
				timeKeepingList={timeKeeping}
			/>
		</>
	);
};
export default SH_time_keeping;
