import React from 'react';
import OderHistory from '../OderHistory';

Manageorder.propTypes = {};

function Manageorder(props) {
	return (
		<div>
			<OderHistory />
		</div>
	);
}

export default Manageorder;
