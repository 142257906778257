import React from 'react';
import { Card } from 'antd';


const BlankCard = ({ children, className, sx, style }) => {
	// const customizer = useSelector((state: AppState) => state.customizer);

	// const theme = useTheme();
	// const borderColor = theme.palette.divider;

	return (
		<Card
			sx={{
				p: 0,
				border: `1px solid #eee`,
				position: 'relative',
				sx,
			}}
			className={className}
			// elevation={customizer.isCardShadow ? 9 : 0}
			// variant={!customizer.isCardShadow ? 'outlined' : undefined}
			elevation={9}
			variant={'outlined'}
			style={style}
		>
			{children}
		</Card>
	);
};

export default BlankCard;
