import React, { useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import service from '../../../app/api/service';
import { Card, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';


function SH_detail_news() {
	const [blogs] = useState([]);

	const params = useParams();
	const [blog, setProduct] = useState({});

	useEffect(() => {
		if (params.id) {
			try {
				getItem().then(r => r);
			} catch (error) {
				console.log(error);
			}
		}
	}, [params.id, blogs]);
	const getItem = async () => {
		service.callApi('findOne', {
			collection: 'news', filter: {
				uniqueID: Number(params.id),
			},
		}).then((res) => {
			console.log(res);
			setProduct(res.data.document);
		});
	};

	function htmlContent() {
		return { __html: blog?.content };
	}

	function htmlDescription() {
		return { __html: blog?.description };
	}

	const match = useRouteMatch();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [match.url]);
	if (blog?.length === 0) return null;
	return (<Row gutter={16} className="flex justify-center mt-4">
		<Col span={16}>
			<img alt="img-blog" className="w-full rounded-t-md" src={blog?.images?.url} />
			<Card bordered={false} title={
				<>    <Title level={2}>{blog?.title}</Title>
					<div className="flex justify-end align-items-center">
						<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-point"
							 width="16"
							 height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
							 strokeLinecap="round" strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<circle cx="12" cy="12" r="4"></circle>
						</svg>
						<span>{dayjs(blog.createdAt).format('DD/MM/YYYY')}</span>
					</div>
				</>


			}
				  className="rounded-t-none mt-[-10px]">
				{/*<h2 className="bg-white mb-0 font-bold text-3xl rounded-none">{blog?.title}</h2>*/}
				<div dangerouslySetInnerHTML={htmlContent()} />
				<div dangerouslySetInnerHTML={htmlDescription()} />
			</Card>
		</Col></Row>);
}

export default SH_detail_news;
