import { Box, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import serviceApi from '../../../app/api/service';
import { Rating, Skeleton } from '@material-ui/lab';
import CardContent from '@material-ui/core/CardContent';
import { Button, Col, Row, Space } from 'antd';
import InputAdornment from '@material-ui/core/InputAdornment';
import BlankCard from '../../../components/BankCard';
import BankCard from '../../../components/BankCard';
import ProductFilter from './ProductFilter';

function Products(props) {
	const [products, setProducts] = useState([]);
	const [category] = useState([]);

	const match = useRouteMatch();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [match.url, category]);
	// skeleton
	const [isLoading, setLoading] = React.useState(true);

	useEffect(() => {
		loadData().then(r => r);
		const timer = setTimeout(() => {
			setLoading(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, []);
	const loadData = async () => {
		serviceApi
			.callApi('find', {
				collection: 'products',
				projection: {},
			})
			.then((res) => {
				console.log(res);
				setProducts(res.data.documents);
			});
	};
	return (
		<div className="flex justify-content-center">
			<Row className="max-w-7xl flex justify-content-center">
				{/*<Col span={6}>*/}
				{/*	<BankCard>*/}
				{/*		<ProductFilter />*/}
				{/*	</BankCard>*/}
				{/*</Col>*/}

				<Col span={24}>
					<Box>
						{/* ------------------------------------------- */}
						{/* Page Listing product */}
						{/* ------------------------------------------- */}
						<Col span={24}>
							<Row gutter={{ xs: 8, sm: 16, md: 24 }}>
								{products.length > 0 ? (
									<>
										{products.map((product) => (
											<Col
												key={product.id}
												span={6}
												style={{ paddingRight: 0, paddingLeft: 0 }}
											>

												{isLoading ? (
													<>
														<Skeleton
															variant="rectangular"
															width={270}
															height={300}
															sx={{ borderRadius: (theme) => theme.shape.borderRadius / 5 }}
														></Skeleton>
													</>
												) : (
													<BlankCard className="hoverCard h-[500px] mb-1 ml-1">
														<Typography component={Link}
																	to={`/products/${product.uniqueID}`}>
															<img src={product.images.url} alt="img" width="100%" />
														</Typography>
														<CardContent sx={{ p: 3, pt: 2 }}>
															<Typography variant="h6">{product.title}</Typography>
															<Space
																direction="row"
																alignItems="center"
																justifyContent="space-between"
																mt={1}
															>
																<Space direction="row" alignItems="center">
																	<Typography
																		variant="h6">{product.price}</Typography>
																	<Typography
																		color="textSecondary"
																		ml={1}
																		sx={{ textDecoration: 'line-through' }}
																	>
																	</Typography>
																</Space>
															</Space>
														</CardContent>
													</BlankCard>
												)}
											</Col>
										))}
									</>
								) : (
									<>
										<Grid item xs={12} lg={12} md={12} sm={12}>
											<Box textAlign="center" mt={6}>
												{/*<img src={emptyCart} alt="cart" width="200px" />*/}
												<Typography variant="h2">There is no Product</Typography>
												<Typography variant="h6" mb={3}>
													The Product you are searching is no longer available.
												</Typography>
												<Button variant="contained"
													// onClick={() => dispatch(filterReset())}
												>
													Try Again
												</Button>
											</Box>
										</Grid>
									</>
								)}
							</Row>
						</Col>
					</Box>
				</Col>
			</Row></div>
	);
}

export default Products;
