import { Box, Button, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';

Cart.propTypes = {};

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2, 0),
		marginTop: '20px',
	},
	flex: { display: 'flex' },
	flexbetween: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(2, 0),
	},
	originalPric: {
		color: theme.palette.grey[600],
	},
	promotion: {
		marginLeft: theme.spacing(2),
	},
	padding: {
		padding: theme.spacing(2),
	},
	button: {
		cursor: 'pointer',
		margin: theme.spacing(0, 1),
	},
	input: {
		border: 'none',
		background: 'transparent',
		width: '35px',
		textAlign: 'center',
		fontSize: ' 13px',
		appearance: 'none',
		margin: '0px',
		height: '30px',
		borderTop: '1px solid rgb(200, 200, 200)',
		borderBottom: '1px solid rgb(200, 200, 200)',
		padding: ' 6px 12px',
	},
	img: {
		maxWidth: '130px',
	},
	spanbutton: {
		border: '1px solid rgb(200, 200, 200)',
		color: 'rgb(153, 153, 153)',
		padding: ' 6px 12px',
		cursor: 'pointer',
		height: '30px',
		width: '30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '14px',
	},
	totalPricee: {
		color: 'rgb(254, 56, 52)',
		fontSize: '22px',
		fontWeight: '400',
		'& > nth:child(first-child)': {
			borderTop: '1px solid rgb(200, 200, 200)',
		},
	},
	leftcol: {},
	rightcol: {
		[theme.breakpoints.down('md')]: {
			marginTop: '15px',
		},
	},
}));
function Cart(props) {
	// const cart = JSON.parse(localStorage.getItem('products'));
	const [cart, setCart] = useState([]);
	const [totalPrice, setTotalPrice] = useState(0);
	const match = useRouteMatch();

	const classes = useStyles();
	useEffect(() => {
		const _product = JSON.parse(localStorage.getItem('products'));

		setCart(Array.isArray(_product) ? _product : []);
	}, []);
	useEffect(() => {
		console.log({ cart });

		const getTotal = () => {
			const total = cart.reduce((total, item) => {
				return (total += item.quantity * item.price);
			}, 0);

			setTotalPrice(total);
		};
		cart?.length > 0 && getTotal();
	}, [cart]);

	const addToCart = async (cart) => {
		localStorage.setItem('products', JSON.stringify(cart));
		// await axios.patch('/user/addcart', { cart }, { headers: { Authorization: token } });
	};

	const increment = (product) => {
		cart.forEach((item) => {
			if (item._id === product._id) {
				product.quantity += 1;
			}
		});
		setCart([...cart]);
		addToCart(cart);
	};
	const decrement = (product) => {
		cart.forEach((item) => {
			if (item._id === product._id) {
				product.quantity === 1 ? (product.quantity = 1) : (product.quantity -= 1);
			}
		});
		setCart([...cart]);
		addToCart(cart);
	};
	const handleRemoveItem = (product) => {
		if (window.confirm('Do you want to remove this product from your shopping cart?')) {
			let idex;
			cart.map((item, index) => {
				if (item._id === product._id) {
					idex = index;
					cart.splice(idex, 1);
				}
			});
			// setCart([...cart]);
			addToCart(cart);
		}
	};

	return (
		<Box className={classes.root}>
			<Container>
				<BreadCrumb str={match.url} />
				<Grid container spacing={2}>
					<Grid item xs={12} lg={8} className={classes.leftcol}>
						<Paper className={classes.padding}>
							<Box>
								{cart.length > 0 ? (
									cart.map((product) => (
										<Box
											className={classes.flex}
											justifyContent="space-between"
											padding="9px 0"
											component="li"
											key={product._id}
											style={{
												borderBottom: '1px solid #eee',
											}}
										>
											<img className={classes.img} src={product.images.url} alt="anh product" />
											<Box>
												<Typography variant="body2">{product.title}</Typography>
												<Button
													style={{
														fontSize: '14px',
														marginLeft: '-15px',
													}}
													onClick={() => handleRemoveItem(product)}
													size="small"
													color="secondary"
												>
													Delete
												</Button>
											</Box>
											<Box>
												<Box>
													<form>
														<Box className={classes.flex}>
															<Typography
																className={classes.spanbutton}
																variant="body2"
																onClick={() => decrement(product)}
															>
																-
															</Typography>
															<input
																className={classes.input}
																value={product.quantity > 0 ? product.quantity : 0}
															/>
															<Typography
																className={classes.spanbutton}
																variant="body2"
																onClick={() => increment(product)}
															>
																+
															</Typography>
														</Box>
													</form>
												</Box>
											</Box>
										</Box>
									))
								) : (
									<Typography>There are no products in the cart</Typography>
								)}
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={4} className={classes.rightcol}>
						<Button style={{ marginTop: '10px' }} variant="outlined" color="secondary">
							<Link to="checkout/payment">Continue</Link>
						</Button>
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: '10px',
							}}
						></Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default Cart;
