import React, { useState } from 'react';

export const ContextGlobal = React.createContext();

const ProviderContext = ({ children }) => {
	const [token, setToken] = useState(false);
	const data = {
		token: [token, setToken],
	};

	return <ContextGlobal.Provider value={data}>{children}</ContextGlobal.Provider>;
};
export default ProviderContext;
