import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { default as React } from 'react';
import { Link } from 'react-router-dom';

ProductItem.propTypes = {
	product: PropTypes.object,
	handleClick: PropTypes.func,
};
const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		width: '100%',
		borderRadius: '0',
		border: '1px solid #edeef5',

		'&:hover': {
			border: '1px solid #c2c2d3',
			borderRadius: '5px',
			boxShadow:
				'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
		},
	},
	img: {
		margin: '0 auto',
		height: '200px',
		objectFit:"fill"
	},
	title: {
		fontSize: '14px',
		textOverflow: 'ellipsis',
		fontWeight: '500',
		'&:hover': {
			color: '#233a95',
		},
		textTransform: 'capitalize',
		display: '-webkit-box',
		maxWidth: '100%',
		margin: '0 auto',
		lineHeight: 1.5,
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		textAlign: 'center',
	},
	description: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	salePercen: {
		marginLeft: theme.spacing(1),
		color: '#c2c2d3',
		fontWeight: '600',
	},
	price: {
		fontSize: theme.spacing(2) + 2,
		fontWeight: '600',
		color: '#d51243',
		textAlign: 'center',
	},
}));

function ProductItem({ product = {} }) {
	const classes = useStyles();

	return (
		<Card elevation={0} className={classes.root}>
			<CardActionArea>
				<Link to={`/products/${product.uniqueID}`}>
					<CardMedia
						component="img"
						alt="Contemplative Reptile"
						image={product.images.url}
						title="Xem ngay"
						className={classes.img}
					/>
				</Link>
				<CardContent style={{ textAlign: 'center' }}>
					<Link to={`/products/${product._id}`}>
						<Typography
							gutterBottom
							variant="h4"
							component="h2"
							className={clsx(classes.title, 'font-family-great-viber')}
						>
							{product.title}
						</Typography>
					</Link>
					<b className="font-family-great-viber" style={{ color: 'red' }}>Contact</b>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default ProductItem;
