import { UploadOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Image, Input, message, notification, Upload } from 'antd';
import axios from 'axios';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import service from '../../../app/api/service';
import Constant from '../../../lib/constant';
import SH_Model from '../components/SH_Model';

function SH_category_detail(props, ref) {
	const { getCategory } = props;
	const [formInstance, setFormInstance] = useState();
	const [open, setOpen] = useState(false);
	const [form] = Form.useForm();
	const refMode = useRef();
	const [initValue, setInitValue] = useState({ name: '' });
	const [index, setIndex] = useState('');
	const [images, setImages] = useState('');
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	const openNotificationWithIcon = (type, message, description) => {
		api[type]({
			message,
			description,
		});
	};
	useImperativeHandle(ref, () => ({
		create: () => {
			setOpen(true);
			refMode.current = Constant.crud.create;
		},
		update: (name) => {
			service
				.callApi('findOne', {
					collection: 'categories',
					filter: {
						name,
					},
				})
				.then((res) => {
					const { _id, ...remain } = res.data.document;
					setInitValue(remain);
					setImages(res.data.document.images);
					setOpen(true);
					refMode.current = Constant.crud.update;
					setIndex(name);
				});
		},
	}));
	useEffect(() => {
		onFormInstanceReady(form);
	}, []);
	const onFormInstanceReady = (instance) => {
		setFormInstance(instance);
	};
	const handleSubmit = async () => {
		try {
			if (refMode.current === Constant.crud.update) {
				setLoading(true);
				await service.callApi(
					'updateOne',
					{
						collection: 'categories',
						filter: {
							name: index,
						},
						update: {
							$set: {
								...initValue,
								images,
								updatedAt: new Date(),
							},
						},
					},
					props.token
				);

				openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
				onCancel();
				getCategory();
				setLoading(false);
			} else {
				setLoading(true);
				await service.callApi(
					'insertOne',
					{
						collection: 'categories',
						document: {
							...initValue,
							images,
							createdAt: new Date(),
							updatedAt: new Date(),
						},
					},
					props.token
				);

				openNotificationWithIcon('success', 'Thành công', 'Thao tác thành công');
				onCancel();
				getCategory();
				setLoading(false);
			}
		} catch (err) {
			console.log(err);
		}
	};
	const handleRemoveImage = async () => {
		try {
			await axios.post('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/destroy', {
				public_id: images.public_id,
				upload_preset: 'kx4mzert',
				api_key: 525413597814261,
			});
		} catch (error) {
			console.log(error);
		}
	};
	const onCancel = () => {
		setOpen(false);
		refMode.current = '';
		formInstance?.resetFields();
	};
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const handleUpload = () => {
		const formData = new FormData();
		formData.append('file', fileList[0]);
		// formData.append('api_key', '525413597814261');
		formData.append('upload_preset', 'kx4mzert');
		setUploading(true);
		// try {
		// 	service.uploadFile({ file: fileList[0] }).then((res) => {
		// 		setFileList([]);
		// 		setImages({
		// 			url: res.data.url,
		// 			public_id: res.data.public_id,
		// 		});
		// 		console.log(res);
		// 		message.success('upload successfully.');
		// 	});
		// } catch (error) {
		// 	message.error('upload failed.');
		// } finally {
		// 	setUploading(false);
		// }
		// You can use any AJAX library you like
		fetch('https://api.cloudinary.com/v1_1/dfjgtdsbf/image/upload', {
			method: 'POST',
			body: formData,
			redirect: 'follow',
		})
			.then((res) => res.json())
			.then((data) => {
				setFileList([]);
				setImages({
					url: data.url,
					public_id: data.public_id,
				});
				message.success('upload successfully.');
			})
			.catch(() => {
				message.error('upload failed.');
			})
			.finally(() => {
				setUploading(false);
			});
	};
	const prop = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			setFileList([...fileList, file]);
			return false;
		},
		fileList,
	};

	try {
		return (
			<>
				{contextHolder}
				<SH_Model
					open={open}
					handleSubmit={handleSubmit}
					onCancel={onCancel}
					width={500}
					textOk={refMode.current === Constant.crud.create ? 'Tạo mới' : 'Cập nhật'}
					loading={loading}
					title={
						refMode.current === Constant.crud.create ? 'Tạo mới danh mục sản phẩm' : 'Sửa danh mục sản phẩm'
					}
				>
					<label>Tên</label>
					<Input
						value={initValue.name}
						onChange={(e) => setInitValue((prev) => ({ ...prev, name: e.target.value }))}
					/>

					<label>Ảnh</label>

					<Flex align="center">
						<Upload {...prop}>
							<Button icon={<UploadOutlined />}>Chọn ảnh</Button>
						</Upload>
						<Button
							type="primary"
							onClick={handleUpload}
							disabled={fileList.length === 0}
							loading={uploading}
							style={{
								marginLeft: 16,
							}}
						>
							{uploading ? 'Đang tải' : 'Tải lên'}
						</Button>
					</Flex>

					<Flex align="center" className="mt-2">
						<Image width={200} src={images.url} />
						{/*<Button*/}
						{/*	danger*/}
						{/*	type="dashed"*/}
						{/*	className="ml-3 flex align-items-center"*/}
						{/*	onClick={handleRemoveImage}*/}
						{/*>*/}
						{/*	<DeleteOutlined />*/}
						{/*	Xóa ảnh*/}
						{/*</Button>*/}
					</Flex>
				</SH_Model>
			</>
		);
	} catch (error) {
		console.log(error);
	}
}

export default forwardRef(SH_category_detail);
