import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Admin from '../Admin';
import BackToTop from './Components/BackToTop';
import Cart from './Components/Cart/cart';
import ContactUs from './Components/ContactUs';
import DetailOrder from './Components/DetailOrder';
import OrderCheckoutDetails from './Components/DetailOrder/OrderCheckout';
import SH_detail_product from './Components/DetailProduct/SH_detail_product';
import SHFooter from './Components/Footer/footer';
import SHHeader from './Components/Header/header';
import HomePage from './Components/Home';
import Login from './Components/Login/login';
import NotFound from './Components/NotFound/notFound';
import OderHistory from './Components/OderHistory';
import ListPage from './Components/Products/listPages';
import RegisterEmail from './Components/RegisterEmail';
import User from './Components/UserInfor';
import { Layout } from 'antd';
import SH_news_page from './pages/news/news';
import { useEffect, useState } from 'react';
import SH_detail_news from './Components/News/SH_detail_news';
import Member from '../Member';
import ButtonsSocial from './Components/ButtonsSocial';

const { Content } = Layout;

function Index() {
	const isAdmin = localStorage.getItem('isAdmin');
	const isMember = localStorage.getItem('isMember');
	const url = useHistory();
	const [pathName, setPathName] = useState('');
	useEffect(() => {
		handleClick();
	}, []);
	useEffect(() => {
		const intervalId = setInterval(() => {
			if (url.location.pathname !== pathName)
				setPathName(url.location.pathname);
		}, 1000);
		return () => {
			clearInterval(intervalId);
		};

	});
	const handleClick = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<Layout>
			{pathName === '/login' ? <Switch><Route path="/login" exact component={Login} /></Switch>
				:
				<>
					{!isAdmin && !isMember && <SHHeader />}
					{(isAdmin || isMember) ? (
						<Route>
							{isAdmin ? <Redirect to="/admin" /> : null}
							<Route path="/admin" component={Admin} />

							{isMember ? <Redirect to="/member" /> : null}
							<Route path="/member" component={Member} />
						</Route>
					) : (
						<Content>
							<Switch>
								<Route path="/" exact component={HomePage} />
								<Route path="/products" exact component={ListPage} />
								<Route path="/products/:id" exact component={SH_detail_product} />
								<Route path="/cart" exact component={Cart} />
								<Route path="/checkout/payment" exact component={RegisterEmail} />

								<Route path="/history" exact component={OderHistory} />
								<Route path="/history/details/:id" exact component={DetailOrder} />
								<Route path="/checkout/details/:id" exact component={OrderCheckoutDetails} />
								<Route path="/user" component={User} />
								<Route path="/news" exact component={SH_news_page} />
								<Route path="/news/:id" exact component={SH_detail_news} />
								<Route path="/contact-us" component={ContactUs} />
								<Route path="*" component={NotFound} />
							</Switch>
						</Content>
					)}
					{(isAdmin || isMember) ? null : <BackToTop />}
					{(isAdmin || isMember) ? null : <ButtonsSocial />}
					{!isAdmin && !isMember && <SHFooter />}
				</>
			}
		</Layout>
	);
}

export default Index;
